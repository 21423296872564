@import '../../../assets/styles/constants.scss';

.info-card-container {
    display: grid;
    border-radius: 4px;
    background: #ffffff;
    @include card();
    max-height: 150px;
    @media (max-width: 768px) {
        margin: 5px 0px;
        padding: 10px 0px 0px 0px;
    }
}

.container-left-colored {
    grid-template-columns: 2fr 3fr;
}

.container-backfill-lefticon {
    grid-template-columns: auto 2fr;
}

.container-backfill-righttopicon {
    grid-template-columns: 1fr auto;
}

.info-card-icon {
    display: grid;
    border-radius: 4px 0px 0px 4px;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.icon-background-topright {
    margin: 5px;
    align-self: start;
}

.icon-background-left {
    margin-left: 20px;
}

.icon-boxfill-topleft {
    height: 70px;
    width: 70px;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 2px;
    margin-left: 15px;
    margin-top: -15px;
    @include shadow();
}

.special-container {
    background: #ffffff;
    height: auto;
    grid-template-rows: 75% auto 25%;
    @media (max-width: 768px) {
        margin: 5px 0px;
        padding: 10px;
    }
}

.container-boxfill-lefticon {
    display: grid;
    grid-template-columns: 1fr 3fr;
    border-radius: 4px 4px 0px 0px;
}

.info-card-data-boxfill {
    margin-right: 5px;
    display: grid;
    padding: 5px;
    grid-row-gap: 0px;
    align-items: center;
    text-align: end;
}

.info-card-data-divider-special {
    height: 1px;
    background: #c7c7c7;
    margin: 0% 5%;
}

.boxfill-bottom-container {
    background: #fff;
    border-radius: 0px 0px 4px 4px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 3px;
    align-items: center;
    align-content: center;
    justify-items: start;
    padding-left: 5%;
}

.boxfill-icon-bottom-text {
    color: #37474f;
    margin-bottom: -0.5em;
    align-self: center;
}

.top-fill-container {
    grid-template-rows: 1fr 3fr;
    background: #ffffff;
}

.top-fll-title-icon {
    display: grid;
    color: #ffffff;
    border-radius: 4px 4px 6px 4px;
    grid-template-columns: 1fr auto;
    padding: 15px 10px;
    @include card();
}

.top-fill-data {
    display: grid;
    text-align: center;
    align-content: center;
    font-size: 32px;
    border-radius: 0px 0px 4px 4px;
}

.info-card-data {
    width: 100%;
    display: grid;
    background: #ffffff;
    border-radius: 0px 4px 4px 0px;
    grid-template-rows: 2fr auto 3fr;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.info-card-data-background {
    width: 100%;
    display: grid;
    color: #ffffff;
    border-radius: 0px 4px 4px 0px;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.info-card-data-title {
    width: 100%;
}

.info-card-data-divider {
    height: 1px;
    background: #c7c7c7;
    width: 100%;
}
//---------------- start top right icon

.back-fill-right-icon {
    display: grid;
    grid-template-rows: 1fr 3fr;
    align-items: center;
    grid-column-gap: 5px;
    background: #ffffff;
    @include card();
    height: 80%;
    padding: 5px;
}

.back-fill-right-icon-info {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    padding-left: 10px;
    text-align: left;
    align-items: center;
    justify-items: left;
    height: fit-content;
}

.back-fill-right-icon-icon {
    display: grid;
    color: gray !important;
    align-self: center;
    margin: 5%;
}
.back-fill-right-icon-title {
    color: gray;
    text-align: left;
    font-size: 14px;
}
.back-fill-right-icon-data {
    color: #1f2833;
    font-size: 28px;
    font-weight: bold;
}

//---------------- container round icon
.left-round-icon {
    display: grid;
    border-radius: 4px;
    background: #ffffff;
    @include card();
    padding: 10px 20px;
    grid-template-columns: auto 2fr;
    grid-column-gap: 20px;
    align-items: center;
    @media(max-width:768px){
        margin:5px 0px;
        padding:10px;
     }
}

.left-round-icon-icon {
    @include card();
    height: 50px;
    width: 50px;
    border-radius: 30%;
    align-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
}

.left-round-icon-info {
    display: grid;
    align-items: center;
    height: fit-content;
    align-content: center;
}

.left-round-icon-data {
    font-size: 30px;
    font-weight: 500;
}
.left-round-icon-title {
    font-size: 12px;
    color: gray;
    padding-bottom: 3px;
    border-bottom: 1px solid rgba(227, 225, 225, 0.67);
}

//-----------start colored icon bottom info

.icon-bottom-info {
    display: grid;
    padding: 10px 20px;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    align-items: start;
    justify-items: center;
    text-align: center;
    height: fit-content;
    @include card();
    @media(max-width:768px){
       margin:5px 0px;
       padding: 10px;
    }
}

.icon-bottom-info-icon {
    padding: 15px;
    margin-top: 5px;
    border-radius: 25%;
}

.icon-bottom-info-info {
    display: grid;
}

.icon-bottom-info-data {
    font-size: 30px;
    font-weight: 500;
}
.icon-bottom-info-title {
    font-size: 14px;
    color: gray;
}

//---------------- start left color icon

.left-color-icon {
    display: grid;
    grid-template-columns: 2fr 5fr;
    align-items: center;
    grid-column-gap: 10px;
    height: fit-content;
    background: #ffffff;
    @include card();
    padding: 5px;
    @media(max-width:768px){
        margin:5px 0px;
        padding: 10px;
     }

}

.left-color-icon-icon {
    display: grid;
    align-self: center;
    margin: 10%;
    @include card();
    justify-items: center;
    padding: 15px;
}

.left-color-svg-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    @include card();
}

.left-color-svg-icon {
    display: grid;
    align-items: center;
    margin: 5%;
    width: 60px;
    height: 60px;
    // @include card();
    justify-items: center;
    padding: 10px;
    border-radius: 50%/50%;
}

.left-color-icon-info {
    display: grid;
    align-items: center;
    height: fit-content;
    align-content: center;
}
.left-color-icon-title {
    color: gray;
    font-size: 14px;
}

.left-color-icon-data {
    font-size: 24px;
    font-weight: 500;
}

//--------------start another for form decoration
.form-decoration-title-container {
    display: grid;
    height: min-content;
    padding-bottom: 20px;
    grid-template-columns: 100px auto;
    border-bottom: 1px solid #e0e5ec;
}
.form-decoration-icon {
    height: 70px;
    width: 70px;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 2px;
    margin-left: 10px;
    margin-top: -30px;
    @include card();
    background-color: #ff3300;
}
.form-decoration-title {
    font-weight: bold;
    font-size: 20px;
}
.table-decoration-title-container {
    display: grid;
    height: min-content;
    padding-bottom: 40px;
    grid-template-columns: 45% auto;
}
.table-decoration-icon {
    height: 60px;
    width: 80px;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 2px;
    margin-left: auto;
    margin-top: -30px;
    @include card();
    background-color: #b7c2c2;
}

.table-decoration-title {
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
    margin-top: -5px;
}
.phoneLoginButton {
    width: 100%;
}

.container-page-signin {
    height: 100vh;
    display: grid;
    background-color: #e0e5ec;
    grid-template-rows: 1fr 3fr;
}

.container-login-banner {
    align-items: center;
    display: grid;
    height: 140px;
    width: 140px;
    justify-content: center;
    border-radius: 20px;
    justify-self: center;
    align-self: center;
}

.container-login-form {
    min-width: 20%;
    display: grid;
    align-self: start;
    justify-self: center;
    margin-top: 20px;
    padding: 20px;
    border-radius: 20px;
}

.container-parallel-login-btn {
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.btn-submit {
    float: right;
    margin-top: 40px;
}

.btn-submit {
    float: right;
    margin-top: 40px;
}

.btn-signin {
    width: 100%;
    height: 36px;
    margin: 10px 0px;
}

.ant-input {
    background: #fff;
    /*border: none;*/
    height: 38px;
    /*box-shadow: inset 6px 6px 12px rgba(163,177,198 ,0.6),*/
    /*inset -6px -6px 12px  rgba(255,255,255, 0.5);*/
}

.ant-input:focus {
    /*border: none;*/
    height: 38px;
    /*box-shadow: inset 6px 6px 12px rgba(163,177,198 ,0.6),*/
    /*inset -6px -6px 12px  rgba(255,255,255, 0.5);*/
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
    background: none;
}

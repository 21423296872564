.red-tag-button:active {
    border: none;
    outline: none;
}
.red-tag-button:focus {
    border: none;
    outline: none;
}
.red-tag-button:hover {
    box-shadow: 1px 1px 8px rgb(252, 220, 220);
    transition: 0.3s ease;
}
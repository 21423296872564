@import '../../assets/styles/constants.scss';

.page-visitor-topbar {
    display: grid;
    height: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2%;
    margin-top: 10px;
    margin-bottom: 5px;
    z-index: 199;
}
.profile-container {
    background: #fff;
    margin: 20px 0px;
    padding: 20px 0px 80px 0px;
    border-radius: 12px;
    .profile-title {
        text-align: center;
        img {
            height: 120px;
            width: 120px;
            border-radius: 50%;
        }
        h1 {
            font-size: 28px;
        }
    }
    .profile-info {
        border: 1px solid #dadce0;
        padding: 20px 10px;
        background: rgb(2, 0, 36);
        background: linear-gradient(
            90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(254, 254, 254, 0) 0%,
            rgb(250, 227, 227) 100%
        );
        box-shadow: 2px 2px 10px gray;
        border-radius: 8px;
        max-width: 700px;
        margin: 0px auto;
        .info-row {
            .single-row {
                padding: 15px 0px 15px 24px;
                display: grid;
                grid-template-columns: 3fr 6fr;
                grid-column-gap: 5%;
            }
            .border-bottom {
                // border-bottom: 1px solid #dadce0;
                margin-left: 24px;
            }
        }
        .info-row:hover {
            background-color: #f0f1f1;
            .border-bottom {
                margin-left: 0px;
            }
        }
    }
}

.pricing-plan-container {
    background: #fff;
    margin: 20px 0px;
    border-radius: 12px;
    box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
    padding: 20px 3px 50px 30px;
    width: 100%;
    .wrapper {
        padding: 0 10%;
        .pricing-container-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title {
                width: 50%;
                @media only screen and (max-width: 992px) {
                    width: 100%;
                }
                h1 {
                    font-size: 28px;
                }
            }
        }
        .plan-grid {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .pricing-title {
                width: 60%;
                display: grid;
                grid-column-gap: 15px;
                grid-template-columns: 1fr 1fr 1fr;
                @media only screen and (max-width: 992px) {
                    width: 100%;
                    grid-column-gap: 5%;
                }
                .active_item {
                    background-color: #14ab72;
                    display: inline;
                    padding: 30px 10px;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    cursor: pointer;
                }
                .plan_item {
                    background: #ff3300;
                    display: inline;
                    padding: 30px 10px;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        table {
            padding: 20px 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
            tbody {
                display: flex;
                flex-direction: column;
                tr:last-of-type {
                    border-bottom: none;
                }
                tr {
                    border-bottom: 1px solid #ccc;
                    // min-height: 60px;
                    display: flex;
                    align-items: center;
                    // padding: 20px 10px;
                    td:first-of-type {
                        width: 40%;
                        text-align: start;
                        margin-left: 15px;
                        position: relative;
                    }
                    td:nth-of-type(4) {
                        margin-left: 12px;
                    }
                    td:nth-of-type(3) {
                        margin-left: 15px;
                    }
                    td {
                        width: calc(60% / 3);
                        font-size: 18px;
                        padding: 6px;
                        // margin-right: 6px;
                        // margin-left: 7px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
.item_shadow {
    background: #bdf0dc;
    padding: 6px;
}

.btn_view {
    display: none;
}
.ant-alert-close-icon {
    left: 98%;
    @media (max-width: 768px) {
        left: 95%;
    }
}
.subscription-button {
    left: 90%;
    z-index: 12;
    right: 0;
    top: -53px;
    position: relative;
}
.pricing-plan-card-container {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    column-gap: 60px;
    padding: 30px;
    margin: auto 12%;
    align-items: center;
}
.pricing-plan-card {
    box-shadow: 2px 2px 4px 0px #d3d3d3, -1px -1px 6px #d3d3d3;
    text-align: center;
    padding: 40px 20px;
    background-position: center;
    background-color: #fefefe;
    border-radius: 12px;
}
.active-card {
    // color: #fff;
    color: #000;
    box-shadow: 2px 2px 4px 0px #d3d3d3, -1px -1px 6px #d3d3d3;
    transform: scale(1.1);
    background-position: center;
    background: rgb(250, 227, 227); /* fallback for old browsers */
    background: linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, rgb(250, 227, 227) 100%);
}
.pricing-plan-card-items {
    padding: 0;
    text-align: left;
}
.pricing-plan-card-items li {
    list-style-type: none;
    font-size: 18px;
    padding: 1px;
    // text-align: center;
}
.select-plan-active-btn {
    border: none;
    padding: 6px 24px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    background-color: #ff3300;
    box-shadow: 2px 2px 4px gray;
    &:hover {
        background-color: #bb2500;
    }
    transition: 0.3s;
    &:focus,
    &:active {
        outline: none;
        border: none;
    }
}
.select-plan-btn {
    color: #000;
    border: none;
    padding: 6px 24px;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 2px 2px 4px #858585;
    background: rgb(250, 227, 227); /* fallback for old browsers */
    background: linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, rgb(250, 227, 227) 100%);

    transition: 0.4s ease;
    &:hover {
        opacity: 0.7;
    }

    &:focus,
    &:active {
        outline: none;
        border: none;
    }
}
.page-perspective-container {
    background-color: #fff;
    margin-top: 20px;
    padding: 40px 100px;
    height: 100vh;
}

.page-perspective-card-container {
    border-radius: 12px;
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
.perspective-card {
    background-color: #fdfdfd;
    border-radius: 8px;
    padding: 20px 30px;
    margin-top: 20px;
    box-shadow: 1px 1px 4px lightgray;
}
.perspective-card h1 {
    font-size: 26px;
}
.cart-container {
    background: #fff;
    margin: 20px 0px;
    border-radius: 12px;
    box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
    padding: 20px 3px 50px 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.cart-card {
    background-color: #fefefe;
    padding: 20px;
    height: 40vh;
    box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
    border-radius: 8px;
}
.cart-item-card h1 {
    margin: 0 10px 0 0;
}
.cart-item-card {
    background: #fff;
    // box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
    border: 1px solid lightgray;
    padding: 6px 12px;
    border-radius: 6px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid lightgray;
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}

@import '../../../assets/styles/constants.scss';

.web-profile-button {
    margin-top: 35%;
    font-size: 36px;
}

.sidebar-title {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 60px;
    text-align: center;
    color: #1f2833;
    font-weight: bold;
    align-self: center;
    font-size: 20px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.sidebar-logo-container {
    height: 40px;
    width: 40px;
    display: grid;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    margin: 11px;
}

.sidebar-item-container {
    width: 100%;
}

.icon-sidebar-layout {
    justify-content: center;
    display: grid;
    grid-template-columns: 30% 50%;
    grid-column-gap: 5px;
    text-align: start;
    padding: 10px 10%;
    transition: 300ms;
    color: rgb(169, 180, 180);
}

.icon-sidebar-layout:hover {
    background: rgb(0, 0, 0);
    color: #fff;
}

.icon-sidebar-layout:focus {
    background: rgb(0, 0, 0);
    color: #fff;
}

.icon-sidebar-layout-mobile {
    height: 100%;
    display: grid;
    text-align: start;
    padding: 20px 0px;
    align-content: center;
    justify-content: center;
    color: rgb(251, 255, 255);
}

.icon-sidebar-layout-mobile:hover {
    height: 100%;
    display: grid;
    text-align: start;
    padding: 20px 0px;
    background: rgb(206, 221, 218);
    align-content: center;
    justify-content: center;
    color: rgb(11, 12, 12);
}

a {
    width: 100%;
}

a:hover {
    text-decoration: none;
}

.text-sidebar {
    text-align: start;
}

// .icon-sidebar {
// }

// .icon-sidebar:hover {
// }

.topbar {
    width: 85%;
    height: 10%;
    background: #fff;
    display: grid;
    position: fixed;
    top: 0;
    grid-template-columns: 86% 10%;
    @include shadow();
}

.ant-calendar-picker-clear {
    display: none;
}
.ant-tag {
    font-size: 16px !important;
    padding: 2px 7px;
}
.topbar-mobile {
    width: 100%;
    height: 10%;
    background: #fff;
    display: grid;
    position: fixed;
    top: 0;
    grid-template-columns: 15% 65% 20%;
    @include shadow();
}

.topbar-pagetitle {
    text-align: center;
    padding-top: 7%;
}

.topbar-menu {
    background: #fff;
    justify-self: right;
    padding: 5px 10px;
    border-radius: 0px;
    align-self: center;
    justify-self: end;
}

.topbar-profile {
    background: #fff;
    justify-self: right;
    padding: 5px 10px;
    border-radius: 0px;
    align-self: center;
    justify-self: end;
}

.icon-topbar {
    border: 1px solid $textBlackColor;
    border-radius: 3px;
    align-self: center;
    justify-self: center;
    padding: 10px;
    transition: 300ms;
}

.icon-topbar:hover {
    background: $textBlackColor;
    color: #fff;
}

.btn-logout {
    width: 100%;
    margin-top: 15px;
    outline: none;
    font-weight: 600;
    background-color: #ff3300;
    color: #fff;
}
.btn-logout:hover {
    background-color: #ff3300;
    color: #fff;
    outline: none;
    border: 0;
}
.btn-profile {
    background: #fff;
    padding: 5px 0px;
    width: 100%;
    color: $textBlackColor;
    letter-spacing: 0.25px;
    margin: 16px 0 0;
    border: 1px solid #dadce0;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
}
.btn-profile:hover {
    background-color: #f7f8f8;
}

header.ant-layout-header {
    padding: 0 20px 10px 0px;
}

span.ant-calendar-picker {
    width: 100%;
}

.app-logo {
    height: 35px;
}
#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px;
}

.site-layout-background {
    background: #f8e9e9;
}
.site-layout-background span {
    display: block;
    align-self: end;
    padding: 15px;
}
//   .fixed-top{
//     position: fixed;
//     top: 0;
//     z-index: 1;
//     height: 48px;
//     line-height: 48px;
//     text-align: center;
//     background: #fff;
//     cursor: pointer;
//     -webkit-transition: all 0.2s;
//     transition: all 0.2s;
//   }

.perspective-small-card {
    background: #fefefe;
    // box-shadow: 2px 2px 10px rgb(184, 184, 184);
    display: inline-block;
    border: 1px solid lightgray;
    margin: 10px;
    padding: 4px 6px; 
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    border-radius: 4px;
    cursor: pointer;
    background-position: left;
    transition: all 0.5s;
}
.perspective-small-card h3 {
    // margin-bottom: 10px;
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}
.perspective-topbar {
    display: flex;
    // overflow-x: auto;
}
.active-perspective-card {
    // border-left: 3px solid;
    // border-image: linear-gradient(2deg, rgb(255, 156, 156), #ff3300);
    // border-image-slice: 1;
    border: 1px solid gray;
    background: #F6F6FE; /* fallback for old browsers */
    color: #000;
}
.active-perspective-card h3::after {
    content: '\2713';
    display: inline-block;
    vertical-align: top;
    line-height: 1.5em;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 4px;
    background-color: #ff3300;
    margin-left: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}
.header-container {
    display: grid;
    grid-template-columns: 1fr 23fr;
    justify-content: center;
    align-items: center;
}
.header-container:first-child {
    margin-bottom: 5px;
}
.header-container img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.perspective-small-card:active {
    // background: linear-gradient(
    //     60deg,
    //     rgba(2, 0, 36, 1) 0%,
    //     rgba(255, 158, 158, 1) 0%,
    //     rgb(255, 51, 0) 100%
    // );

    box-shadow: rgba(0, 0, 0, 0.5) 0 3px 10px 0;
    transition: all 0.8s;
}
.show-perspective {
    background-color: #F6F6FE;
    border: 1px solid gray;
}
.show-perspective h3::after {
    content: '\21C5';
    display: inline-block;
    vertical-align: top;
    line-height: 1.5em;
    width: 25px;
    height: 25px;
    padding: 2px;
    border-radius: 4px;
    // background-color: #ff3300;
    margin-left: 10px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #ff3300;
}
@import 'assets/styles/constants';

$appBackground: #fff;
//$appBackground: #83d0c9;


.container-app-topbar {
    color: black;
    font-weight: bold;
}

.app-topbar {
    background: #fff;
    border-radius: 4px;
    color: #1f2833;
    height: 100px;
    margin: 5px 0px 0px 0px ;
    display: grid;
    align-items: center;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    //     0 1px 3px 0 rgba(0, 0, 0, 0.1);
    grid-template-columns: 21fr 1fr 1fr 1fr;
    padding: 0 20px;
    grid-column-gap: 20px;
}


.app-container-mobile {
    display: grid;
    height: 100vh;
    width: 100%;
    grid-template-rows: 10% 80% 10%;
}

.text-red {
    color: #fe3935;
}

.text-black {
    color: #37474f;
}

.text-blue {
    color: #0079bf;
}

.text-green {
    color: rgb(76, 196, 116);
}

.text-green-deep {
    color: rgb(69, 179, 105);
}

.btn-green {
    //@include card();
    background: #14ab72;
    border: #14ab72;

    transition: 1000ms;
    color: white;
}
.btn-green:focus {
    background: #14ab72;
    border: #14ab72;
    cursor: pointer;
    color: white;
}
.btn-green:hover {
    background: #14ab72;
    border: #14ab72;
    cursor: pointer;
    color: white;
}
.btn-orange {
    //@include card();
    background: #ff6741;
    border: #ff3300;
    transition: 1000ms;
    color: white;
}
.btn-orange:focus {
    background: #ff3300;
    border: #ff3300;
    cursor: pointer;
    color: white;
}
.btn-orange:hover {
    background: #ff3300;
    border: #ff3300;
    cursor: pointer;
    color: white;
}

.btn-red {
    background: #f55f55;
    color: white;
}

.btn-red:hover {
    background: rgb(223, 67, 56);
    transition: 1000ms;
    color: white;
    cursor: pointer;
}

.neumorphic-card {
    @include neomorphic-card();
}

.card {
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    border-radius: 5px;
    padding: 10px;
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.card:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.container-parallel-two {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.container-label-input {
    display: grid;
    grid-template-rows: 20px 1fr;
    grid-row-gap: 5px;
    margin: 10px 0px;
}
.form-image .ant-form-item-label{
    text-align: center;
}
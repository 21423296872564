.dashboard-container {
    margin: 20px;
    margin-top: 0;
    display: grid;
    grid-template-rows: auto auto 1fr;
    /* background-color: #fff; */
    /* padding: 20px; */
}

.dashboard-info-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}

.dashboard-item-container {
    display: grid;
    /* margin-top: 20px; */
    grid-template-rows: 1fr 3fr;
    /* flex-direction: column; */
    padding: 5px 10px;
    background: rgb(254, 254, 254);
    border-radius: 8px;
    border: 1px solid lightgray;
    /* justify-items: center; */
    /* border: 1px solid lightgray; */
    /* border-bottom: 3px solid #001a31; */
    /* box-shadow: 2px 2px 12px rgb(184, 184, 184); */
    cursor: pointer;
}
.bar-chart-container {
    /* box-shadow: 2px 2px 16px rgb(184, 184, 184); */
    border: 1px solid lightgray;
    border-radius: 8px;
    margin-top: 20px;
}
/* .dashboard-item-container:hover {
    transform: scale(1.05);
    transition: 900ms ease-in-out;
    cursor: pointer;
} */

.info {
    text-align: end;
    font-size: 48px;
    font-weight: bolder;
}

.icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
}

.dashboard-entrance-history {
    background: #fff;
    margin-top: 20px;
    border-radius: 4px;
}

.title {
    font-size: 20px;
    font-weight: 700;
}
.dash-main-container {
    display: grid;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px;
    grid-template-columns: 5fr 1fr;
}
.dashboard-side-item-container {
    /* margin-top: 20px; */
    border-radius: 10px;
    /* padding: 20px 0; */
}
.side-item {
    background-color: #fff;
    padding: 18px 14px;
    /* border-radius: 8px; */
    /* margin-top: 20px; */
    /* box-shadow: 2px 2px 12px rgb(184, 184, 184); */
    border-bottom: 1px solid lightgray;
}
.side-item ul {
    list-style-type: none;
    padding: 0;
}
.side-item ul li {
    font-size: 16px;
    padding: 5px 0;
    font-weight: 600;
}
.side-item h1 {
    font-size: 22px;
}
.side-item-body ul li {
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}
.side-item-header {
    display: flex;
    align-items: center;
    padding: 6px 0;
}
.side-item-icon {
    /* background-color: rgb(240, 240, 240); */
    padding: 8px 6px;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}
.side-item-header img {
    width: 20px;
    height: 25px;
}
.side-item-header h1 {
    color: #000D64;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}
.side-item-small-header {
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between;
    align-items: center;
}
.side-item-small-header h1 {
    margin: 0;
    padding: 2px;
}
.side-item-counter span {
    background-color: #0004A1;
    padding: 1px 18px;
    color: #fff;
    font-size: 14px;
    border-radius: 12px;
    font-weight: 500;
}
.button-container {
    text-align: center;
}
.card-header {
    display: flex;
    padding: 4px;
    align-items: center;
}
.card-header h1 {
    margin: 0;
    /* margin-bottom: -10px; */
    margin-left: 10px;
    font-size: 22px;
}
.list-items ul {
    padding: 0 12px;
    margin: 0;
}
.list-items ul li {
    list-style-type: none;
    font-size: 16px;
}
.dashboard-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
}
.dashboard-card-body h1 {
    font-size: 26px;
    text-align: center;
    margin: 0;
}
.bottom-bar-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    padding: 14px 36px;
    background: #fff;
    border-radius: 10px;
    height: 110px;
    flex-wrap: wrap;
    /* box-shadow: 2px 2px 6px lightgray; */
}

.bottom-item-container {
    background-color: rgb(245, 245, 245);
    padding: 10px 22px;
    margin: 4px;
    border-radius: 8px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* box-shadow: inset -2px -2px 6px rgb(230, 230, 230); */
}
.bottom-item-container img {
    /* width: 40px;
    height: 70px; */
    width: 50px;
    padding: 4px;
    margin-right: 10px;
}
.bottom-item-container h2 {
    font-size: 26px;
    font-weight: bold;
}
.bottom-item-container p {
    font-size: 18px;
    font-weight: lighter;
    color: gray;
}
.bottom-item-container h2,
p {
    margin: 0;
}

@media only screen and (max-width: 1280px) {
    .dashboard-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .card-header {
        justify-content: center;
    }
    .card-header h1 {
        font-size: 18px;
    }
    .bottom-bar-container {
        height: fit-content;
    }
    .dashboard-side-item-container {
        display: none;
    }
    .dash-main-container {
        grid-template-columns: 6fr;
    }
}

@media only screen and (max-width: 860px) {
    .dashboard-card-body {
        display: flex;
        flex-direction: row;
    }
    .dashboard-info-container {
        display: flex;
        flex-direction: column;
    }
    .dashboard-container {
        margin: 0 auto;
    }
    .dashboard-side-item-container {
        display: none;
    }
    .bottom-bar-container {
        height: fit-content;
    }
}

@charset "UTF-8";
.toggle-container {
  width: 115px;
  background-color: #dbdbdb;
  cursor: pointer;
  user-select: none;
  height: 40px;
  border-radius: 6px;
  position: relative; }
  .toggle-container::after {
    content: 'বাংলা';
    position: relative;
    font-weight: 800;
    color: #000;
    top: 25%;
    left: 7%; }

.toggle-container-hide {
  width: 115px;
  background-color: #dbdbdb;
  cursor: pointer;
  border-radius: 6px;
  user-select: none;
  height: 40px;
  position: relative; }
  .toggle-container-hide::after {
    content: 'English';
    position: relative;
    font-weight: 800;
    color: #000;
    top: 25%;
    left: 57%; }

.dialog-button {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #000;
  color: white;
  padding: 12px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-width: unset;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(31, 31, 31, 0.25);
  position: absolute;
  left: 50px;
  transition: all 0.3s ease; }

.disabled {
  background-color: #000;
  left: -2px; }

@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@500&display=swap');
body {
    padding-right: 0 !important;
    font-family: 'Baloo Da 2', cursive !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

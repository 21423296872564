.card-container {
    display: flex;
    background-color: #fff;
    min-height: 83vh;
    // width: 70vw;
    justify-content: space-evenly;
    padding: 40px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
    .card-body {
        background-color: rgb(243, 243, 243);
        padding: 28px 6px;
        box-shadow: -2px 2px 4px rgb(233, 232, 232), -2px -2px 4px   rgb(233, 232, 232);
        border-left: 5px solid;
        border-image: linear-gradient(0deg, #fcc4b5, #ff3300);
        border-image-slice: 2;
        transition: all 0.3s;
        height: 230px;
        transition-timing-function: ease;
        cursor: pointer;
        text-align: center;
        img {
            width: 30%;
        }
        h1 {
            margin-top: 12px;
            font-size: 32px;
        }

    }
    .card-body:hover {
        box-shadow: -2px -2px 10px rgb(233, 232, 232), 2px 2px 10px   rgb(233, 232, 232);
        background-color: #fff;
        transform: scale(1.1);
    }
}
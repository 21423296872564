.ant-form-item {
    margin-bottom: 5px;
}
.ant-form-item-label {
    line-height: 30px;
}

.ant-transfer-list {
    width: 46%;
}

.ant-transfer-list-header-title {
    font-size: 15px;
    font-weight: bold;
}
.ant-btn-lg {
    padding: 0 4px!important;
}
span.ant-transfer-list-search-action {
    display: grid;
    align-content: center;
}
.ant-transfer-operation .ant-btn:first-child {
    height: 40px;
    width: 40px;
}
.ant-transfer-operation .ant-btn {
    height: 40px;
    width: 40px;
}

.ant-transfer-list {
    padding-bottom: 20px;
    height: 400px;
}

.ant-transfer-operation {
    display: inline-grid;
    width: 6%;
}

.ant-transfer-operation .ant-btn {
    display: grid;
    justify-self: center;
}

.ant-transfer-list-content {
    margin-top: 20px;
}

.ant-menu-submenu-inline:hover {
    color: #ff3300 !important;
}

.ant-menu-submenu-active:hover {
    color: #efefef !important;
}

.ant-menu-submenu:hover {
    color: #eeeedd !important;
}

.ant-menu-sub.ant-menu-inline {
    /* background: aliceblue !important; */
}

.ant-menu-submenu-selected {
    color: #ff3300 !important;
    background: #F6F6FE !important;
    // border-left: 3px solid;
    border-left: 3px solid #ff3300;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #ff3300!important;
}
.ant-menu-item:hover {
    color: #ff3300 !important;
    background-color: #f4f4f4 !important;
}
.ant-menu-submenu-selected > .ant-menu-item {
    background-color: #F6F6FE!important;
}
.ant-menu-item-selected {
    background: #F6F6FE !important;
    color: #ff3300 !important;
}
.ant-menu-submenu-title {
    color: #00090F;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 45px !important;
    padding-left: 15px !important;
}
.ant-menu-submenu {
    margin-bottom: 0 !important;
    padding-top: 10px !important;
    background-color: #fff;
    border-bottom: 1px solid #eeecec;
}
.ant-menu-item {
    border-top: 1px solid #eeecec;
    font-size: 16px !important;
    height: 45px !important;
    line-height: 46px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-weight: 500 !important;
}
.ant-menu-submenu:focus {
    background-color: #d67070;
}
.ant-menu-submenu-title:active {
    color: #ff3300;
}
.ant-menu-submenu-title:hover {
    color: #ff3300;
}

.ant-menu-inline .ant-menu-item::after {
    background: #ff3300 !important;
    border-right: #ff3300 3px solid !important;
}
.ant-menu-submenu-arrow::before {
    background: #00090F !important;
}
.ant-menu-submenu-arrow::after {
    background: #00090F !important;
}
.ant-menu-submenu > .ant-menu-submenu-arrow::after {
    background: #ff3300 !important;
}
.ant-menu-submenu > .ant-menu-submenu-arrow::before {
    background: #ff3300 !important;
}
.ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background: #f5f8fe !important;
    padding: 10px !important;
}

.ant-table-body {
    margin: 0 !important;
}

a.ant-calendar-month-panel-next-year-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-month-panel-prev-year-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-year-panel-next-decade-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-year-panel-prev-decade-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-decade-panel-next-century-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-decade-panel-prev-century-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-next-year-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-prev-year-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-next-month-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-prev-month-btn {
    height: 10%;
    width: 10%;
}

a.ant-calendar-month-select {
    height: 10%;
    width: 10%;
}

a.ant-calendar-year-select {
    height: 10%;
    width: 10%;
}

.ant-transfer {
    width: -webkit-fill-available;
}

.ant-transfer-list-header-title {
    font-size: 15px;
    font-weight: bold;
}

span.ant-transfer-list-search-action {
    display: grid;
    align-content: center;
}
.ant-transfer-operation .ant-btn:first-child {
    height: 40px;
    width: 40px;
}
.ant-transfer-operation .ant-btn {
    height: 40px;
    width: 40px;
}

.ant-transfer-list {
    padding-bottom: 20px;
}

.ant-transfer-operation {
    display: inline-grid;
    width: 6%;
}

.ant-transfer-operation .ant-btn {
    display: grid;
    justify-self: center;
}
.ant-calendar-picker {
}

.ant-calendar-picker {
    width: -webkit-fill-available;
}

.ant-transfer-list-content {
    margin-top: 20px;
}

.ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;
}

.ant-table-placeholder {
    z-index: 0;
}
.ant-layout {
    display: block;
    background: #e7e7e7!important;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: hidden;
}
.ant-radio-group-large .ant-radio-button-wrapper {
    font-size: 14px;
}

/* Ant Transfer Design For Responsive */
@media (max-width: 768px) {
    .ant-transfer-list {
        width: 100%;
    }
    .ant-transfer-operation {
        margin: 10px 0px;
        width: 100%;
    }
}
.ant-transfer-operation .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    justify-content: center;
}
.ant-transfer-operation .ant-btn .anticon {
    font-size: 40px;
}
.ant-input[disabled] {
    color: rgb(0, 0, 0);
}
.ant-input {
    color: rgb(0, 0, 0);
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: none !important;
}
.bill-checkbox .ant-checkbox-inner {
    top: 1px;
    left: 0;
    width: 35px;
    height: 35px;
}
.bill-checkbox .ant-checkbox-inner::after {
    top: 45%;
    left: 20%;
    width: 11.714286px;
    height: 23.142857px;
}
.ant-layout-sider {
    max-width: 250px !important;
    min-width: 250px !important;
    .ant-layout-sider-trigger {
        width: 250px !important;
        background-color: #002140;
    }
}
.ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    .ant-layout-sider-trigger {
        width: 80px !important;
    }
}
section.ant-layout::-webkit-scrollbar {
    display: none;
}
aside.ant-layout-sider::-webkit-scrollbar {
    display: none;
}
section.ant-layout{
    -ms-overflow-style: none;
    scrollbar-width: none;
}
aside.ant-layout-sider{
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
span.ant-input-suffix {
    top: 55%!important;
    right: 2px!important;
}
@import '.././../assets/styles/constants.scss';

.bill-dashboard-summary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px;
    grid-column-gap: 30px;
}

.dashboard-summary-item {
    border-radius: 5px;
    font-size: 30px;
    @include shadow();
    background: #14ab72;
    color: #fff;
    padding: 10px;
}

.ant-table-content {
    background-color: white;
}
.ant-table-tbody > tr > td {
    word-break: break-word;
    -ms-word-break: break-all;
}
//lists

.list-visitors-container {
    width: 96%;
    margin: 2%;
}

.list-item-special-container {
    display: grid;
    grid-template-rows: 3fr 1fr;
    @include shadow();
    padding: 10px 0px;
    margin-bottom: 10px;
}

.list-item-visitors-container {
    display: grid;
    margin: 2% 5%;
    padding: 2%;
    border: none;
    border-bottom: 1px solid rgb(233, 236, 234);
    grid-template-columns: 20% 70% 10%;
}

.list-item-visitors-image {
    display: grid;
    align-items: center;
}

.list-item-visitors-details {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.list-item-visitors-action {
    align-self: center;
}

//common
.image-user-small {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    overflow: hidden;
}

.image-user-large {
    align-content: center;
    justify-content: center;
    height: 400px;
    width: 400px;
}
.column-center {
    display: grid;
    justify-items: center;
    justify-content: center;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
.bill-table-responsive {
    thead tr th {
        background-color: #fff;
    }
    table,
    td,
    th {
        border: 1px solid #e8e8e8;
    }
    table {
        border: 0 !important;
        th {
            border-top: 0;
        }
    }
    // tr:hover { background-color: rgb(212, 216, 219); }
    td {
        padding: 5px 0px;
    }
}
.generate-table-responsive {
    thead tr th {
        background-color: #fff;
        padding: 5px 0px;
    }
    table,
    td,
    th {
        border: 1px solid #e8e8e8;
    }
    td {
        padding: 5px 0px;
        .ant-input {
            border: 0 !important;
        }
    }
}
@media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    .bill-table-responsive {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tr {
            border: 1px solid #ccc;
        }
        td {
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
        }

        td:before {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }
        td:nth-of-type(1):before {
            content: 'Select';
        }
        td:nth-of-type(2):before {
            content: 'Name';
        }
        td:nth-of-type(3):before {
            content: 'Description';
        }
        td:nth-of-type(4):before {
            content: 'Flat Name';
        }
        td:nth-of-type(5):before {
            content: 'Total';
        }
        td:nth-of-type(6):before {
            content: 'Paid';
        }
        td:nth-of-type(7):before {
            content: 'Month';
        }
        td:nth-of-type(8):before {
            content: 'Payment Status';
        }
        td:nth-of-type(9):before {
            content: 'Due Amount';
        }
        td:nth-of-type(10):before {
            content: 'Collect Amount';
        }
        td:nth-of-type(11):before {
            content: 'Arbitrary Data';
        }
    }
}

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}

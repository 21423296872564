.print-source {
    display: none;
    /* text-align: center; */
}

@media print {
    html * {
        visibility: visible;
    }
    body * {
        visibility: visible;
        overflow: hidden;
    }
    .print-source {
        display: block;
        color: black;
        display: flex;
        justify-content: center;
    }
}
@page {
    margin-top: 1cm;
    margin-bottom: 1cm;
}

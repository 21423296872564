@import '../../../assets/styles/constants';

.container-icon-title {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 5px;
    text-align: start;
    align-items: center;
}

.dropdown-basic-container {
    display: grid;
    padding-right: 5px;
    padding-bottom: 10px;
    grid-template-rows: auto 1fr;
    grid-column-gap: 5px;
    border-radius: 4px;
    background: #ffffff;
    align-content: center;
    align-items: center;
}
.dropdown-basic-container-styled {
    display: grid;
    padding-right: 5px;
    grid-template-columns: 1fr 4fr;
    grid-column-gap: 5px;
    border-radius: 4px;
    margin-bottom: 10px;
    background: #ffffff;
    @include shadow();
    align-content: center;
    align-items: center;
}
.container-dropdown-content {
    display: grid;
    margin-bottom: 5px;
    grid-row-gap: 5px;
    padding: 10px 0px;
}

.container-icon-dropdown {
    display: flex;
    // align-items: center;
    // align-content: center;
    // justify-content: center;
    margin-bottom: 6px;
}

.container-icon-dropdown-styled {
    height: 100%;
    border-radius: 4px 0 0 4px;
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
}

//------------------btn styles

$whiteColor: #fff;
$greenShade: #f1fcf6;
$greenStroke: #14ab72;
$redShade: rgba(253, 238, 233, 0.5);
$redStroke: #e53935;
$blueShade: #e1eff7;
$blueStroke: #0079bf;
$purpleStroke: purple;
$purpleStrokeMild: rgb(59, 1, 59);

.btn-mild-green {
    color: $greenStroke;
    background: $whiteColor;
    border: 1px solid $greenStroke;
}

.btn-fill-green {
    color: #fff;
    background: $greenStroke;
    border: 1px solid $greenStroke;
}
.btn-fill-purple {
    color: #fff;
    background: $purpleStroke;
    border: 1px solid $purpleStroke
}
.btn-fill-purple:hover {
    color: #fff;
    background: $purpleStrokeMild;
    border: 1px solid $purpleStroke
}
.btn-fill-purple:focus {
    color: $purpleStroke;
    background: $whiteColor;
    border: 1px solid $purpleStroke
}
.btn-fill-green:hover {
    color: #fff;
    background: $greenStroke;
    border: 1px solid $greenStroke;
}
.btn-fill-green:focus {
    color: $greenStroke;
    background: $whiteColor;
    border: 1px solid $greenStroke
}
.btn-fill-black {
    color: #fff;
    font-size: 18px;
    min-width: 100px;
    background: #1f2833;
    border: 1px solid #1f2833;
    height: 45px;
    i{
        font-size: 25px;
        font-weight: 800!important;
        position: relative;
        top: 3px;
    }
}

.btn-fill-black:hover {
    color: #fff;
    background: #1f2833;
    border: 1px solid #1f2833;
}

.btn-mild-green:hover {
    color: #fff;
    background: $greenStroke;
    border: 1px solid $greenStroke;
}
.btn-mild-green:focus {
    color: #fff;
    background: $greenStroke;
    border: 1px solid $greenStroke;
}
//--------------- red mild button
.btn-mild-red {
    color: $redStroke;
    background: $whiteColor;
    border: 1px solid $redStroke;
}

.btn-mild-red:hover {
    color: #fff;
    background: $redStroke;
    border: 1px solid $redStroke;
}
.btn-mild-red:focus {
    color: #fff;
    background: #e53935;
    border: 1px solid #e53935;
}

//--------------- blue mild button
.btn-mild-blue {
    color: $blueStroke;
    background: $whiteColor;
    border: 1px solid $blueStroke;
}

.btn-mild-blue:hover {
    color: #fff;
    background: $blueStroke;
    border: 1px solid $blueStroke;
}
.btn-mild-blue:focus {
    color: #fff;
    background: $blueStroke;
    border: 1px solid $blueStroke;
}

.btn-fill-blue{
 color: #fff;
 background: $blueStroke;
 border: 1px solid $blueStroke;
}
.btn-fill-blue:hover{
 color: #fff;
 background: $blueStroke;
 border: 1px solid $blueStroke;
}

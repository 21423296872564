@import "../../assets/styles/constants";
$body-bg: #c1bdba;
$form-bg: rgb(255, 225, 223);
$white: #000000;

$main: #ff3300;;
$main-light: lighten($main, 5%);
$main-dark: darken($main, 5%);

$gray-light: #a0b3b0;
$gray: #ddd;

$thin: 300;
$normal: 400;
$bold: 600;
$br: 4px;

.signin-container {
    display: grid;
    background: url('../../assets/images/rokkhi-admin.png');
    background-position:center; 
    background-repeat:no-repeat; 
    min-height: 90vh;
    grid-template-columns: 1fr;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    .vl {
        border-left: 2px solid rgb(104, 104, 104);
        height: 50vh;
        margin-top: 100px;
        @media (max-width: 768px) {
                display: none;
        }
    }
    .image-container{
        margin: 60px auto;
        @media (max-width: 768px) {
            display: none;
        }   
    }
    .form {
        background: #fff;
        padding: 40px;
        max-width: 600px;
        width: 100%;
        margin: 60px auto;
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(82,82,82,1);
        -moz-box-shadow: 0px 0px 15px -1px rgba(82,82,82,1);
        box-shadow: 0px 0px 15px -1px rgba(82,82,82,1);
        height: fit-content;
    }
    .signin-tab-group {
        .ant-tabs-ink-bar{
            background: none!important;
        }
        .ant-devider{
            margin: 0px 0px!important;
        }
        .ant-tabs-tab-active {
            color: #fff !important;
            background: $main !important;
        }
        .ant-tabs-nav .ant-tabs-tab {
            color: #837878;
            background: #d9d9d9;
            position: relative;
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 60px;
            margin: 0;
            padding: 15px 68%;
            text-decoration: none;
            font-size: 22px;
            cursor: pointer;
            -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            @media (max-width: 768px) {
                padding: 20px 60%;
            }
            &:last-child{
                border-radius: 0px 10px 10px 0px!important;
            }
            &:first-child{
                border-radius: 10px 0px 0px 10px!important;
            }
        }
        .ant-input {
            background: inherit;
            height: 50px;
            border: 1px solid #a0b3b0;
            color: #000000;
            border-radius: 10px;
            &:not(:first-child) {
                padding-left: 50px;
            }
            &:not(:last-child) {
                padding-right: 35px;
            }
            :focus {
                outline: 0;
                border-color: #ff3300 !important;
            }
            :active {
                outline: 0;
                border-color: #ff3300 !important;
            }
        }
        .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
            border-color: #ff3300;
            border-right-width: 1px !important;
        }
    }
    
    h1 {
        text-align: center;
        color: $white;
        font-weight: $thin;
        margin: 0 0 40px;
    }
    .button {
        margin-top: 10px;
        height: auto;
        border: 0;
        outline: none;
        border-radius: 10px;
        padding: 5px 0;
        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        background: #ff3300;
        color: #fff;
        transition: all.5s ease;
        -webkit-appearance: none;
        &:hover,
        &:focus {
            color: #fff;
            border-color:#ff7253;
            background: #ff7253;
        }
    }
    i{
        color: #ff3300;
        font-size: 22px; 
    }
    a{
        color: #000;
    }
    .phone-login-button {
        background: #000;
        &:hover,
        &:focus {
            color: #fff;
            background-color: #414141;
            background: #414141;
        }
    }
    
    .button-block {
        display: block;
        width: 100%;
    } 
}



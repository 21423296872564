.balance-sheet-container {
    background-color: #fff;
    padding: 10px;
    /* margin: 20px; */
    border-radius: 4px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}

.create-bill-package{
    width: inherit!important;
    .modal-content-container {
        display: grid;
        grid-row-gap: 10px;
    }
    
    .ant-modal-content {
        max-height: calc(100vh - 7.5rem) !important;
        height: 100% !important;
        width: 100vw!important;
        max-width: 64rem !important;
    }
   
}
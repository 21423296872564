@import "../../assets/styles/constants.scss";

.modal-content-container {
    display: grid;
    grid-row-gap: 10px;
}

.image-container-modal {
    width: 130px;
    height: 130px;
}

.modal-addguard-layout {
    display: grid;
    grid-row-gap: 20px;
}

.modal-addworker-layout {
    display: grid;
    grid-column-gap: 20px;
}

.modal-addworker-flatlist {
    margin: 20px 5px;
    height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    overflow: auto;
}

.modal-addworker-flat-item-selected {
    padding: 10px 30px;
    text-align: center;
    @include shadow();
    border-radius: 4px;
    color: #fff;
    transition: 300ms;
    background: $blueColor;
    &:hover {
        @include shadow();
    }
}

.modal-addworker-flat-item-unselected {
    padding: 10px 30px;
    text-align: center;
    @include shadow();
    color: $blueColor;
    border-radius: 4px;
    background: #fff;
    border: 1px solid $blueColor;
    transition: 300ms;
    &:hover {
        color: #fff;
        background: $blueColor;
    }
}

.add-admin-item {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 2fr;
    grid-column-gap: 5px;
}

.add-admin-item-nameflat {
    display: grid;
    grid-template-rows: 3fr 2fr;
}

.btn-add {
    background: #fff;
    border: 1px solid;
    border-radius: 4px;
    transition: 300ms;
    &:hover {
        color: #fff;
        background: $blueColor;
    }
}
.logo-image{
    display: none;
}
.qr-code-header{
    margin-bottom: 20px;
    h2{
        font-size: 26px;
        span{
            font-weight: 600;
        }
    }
    h3{
        font-size: 20px;
    }
}
.qr-code-footer{
    text-align: center;
    margin-top: 20px;
    h2{
      font-size: 20px;  
    }
    h3{
        font-size: 18px;

    }
}
@media print {
    html *{
        visibility: hidden;
    }
    body * {
        visibility: hidden;
        overflow: hidden;
      }
      .ant-modal-body * {
        visibility: visible;
      }
    // .no-print{
    //     display: none;
    // }
    .ant-modal-content{
        box-shadow: none;
       
    }
    .qr-code-footer{
        text-align: center;
        h2{
          font-size: 20px;  
        }
        h3{
            font-size: 18px;

        }
    }
    canvas{
        height: 200px!important;
        width: 200px!important;
    }
    .logo-image{
        border-top: 1px solid gray;
        display: block;
        padding-top: 20px;
        margin-bottom: auto;
        img{
            width: 150px;
            max-width: 150px;
        }
    }
    .print-btn {
        display: none;
    }
}

.police-modal {
    input {
        border: 1px solid #CBD5E0!important;

    }
    .user-image{
        border: 2px solid #CBD5E0!important;
    }
}

.print-btn {
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
}
.print-btn:focus,
.print-btn:active {
    border: none;
    outline: none;
}
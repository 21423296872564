.police-verification {
    width: inherit !important;
    .modal-content-container {
        display: grid;
        grid-row-gap: 10px;
    }

    .ant-modal-content {
        max-height: calc(100vh - 7.5rem) !important;
        height: 100% !important;
        width: 100vw !important;
        max-width: 64rem !important;
    }
}

.police-modal {
    word-wrap: normal;
    color: #000;
    padding: 20px 10px;
    .top-section {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(1, 0.75fr 1.25fr 1fr);
        width: 100%;
        .flex-common {
            .flex-label-input {
                width: 100%;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                margin-top: 0.75rem;
                margin-bottom: 0.75rem;
                label {
                    font-size: 1rem;
                    padding-right: 8px;
                    padding-bottom: 4px;
                    opacity: 1;
                    font-weight: 500;
                    text-align: left;
                    vertical-align: middle;
                    display: inline-block;
                    width: 25%;
                }
                input {
                    width: 75%;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    transition: all 0.2s ease 0s;
                    outline: none;
                    appearance: none;
                    font-size: 1rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    height: 2.5rem;
                    border-radius: 0.25rem;
                    border-width: 1px;
                    border-style: solid;
                    border-image: initial;
                    border-color: inherit;
                    // border: none!important;
                }
            }
        }
        .header-left {
            display: flex;
            flex-direction: column;
            width: 100%;
            .user-image {
                border-width: 2px;
                border-radius: 0.5rem;
                height: 180px !important;
                max-width: 20rem;
                border: none!important;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .header-middle {
            width: 100%;
            .police-title {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                img {
                    height: 42px;
                    margin-right: 1rem;
                }
                h2 {
                    font-size: 1.25rem;
                    line-height: 1.25;
                    font-weight: 700;
                }
            }
        }
        .header-right {
            width: 100%;
        }
    }
    .form-header {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 3rem;
        // border: none!important;
        u {
            font-size: 24px;
            line-height: 1.25;
            font-weight: 700;
            text-align: center;
            text-decoration: none;
        }
    }
    .form-element {
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .table-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            .table-title {
                margin-bottom: 0.5rem;
                font-size: 1rem;
            }
            table {
                tr {
                    td {
                        text-align: center;
                        border: 1px solid rgb(119, 119, 119);
                        padding: 6px;
                        font-size: 1rem;
                        input {
                            width: 100%;
                            display: flex;
                            -webkit-box-align: center;
                            align-items: center;
                            position: relative;
                            transition: all 0.2s ease 0s;
                            outline: none;
                            appearance: none;
                            font-size: 1rem;
                            padding-left: 1rem;
                            padding-right: 1rem;
                            height: 2.5rem;
                            border-radius: 0.25rem;
                            border-width: 0px;
                            border-style: initial;
                            border-image: initial;
                            border-color: inherit;
                            background-color: rgb(255, 255, 255);
                        }
                    }
                }
            }
        }
        .emergency-section {
            display: flex;
            width: 100%;
            .emergency-title {
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }
        }
        .form-item-warp {
            display: grid;
            gap: 1.5rem;
            width: 100%;
        }
        .item-no {
            width: 2.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .label-input-wrap {
            width: 100%;
            label {
                font-size: 1rem;
                padding-right: 12px;
                padding-bottom: 4px;
                opacity: 1;
                font-weight: 500;
                text-align: left;
                vertical-align: middle;
                display: inline-block;
            }
            input {
                width: 100%;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                position: relative;
                transition: all 0.2s ease 0s;
                outline: none;
                appearance: none;
                font-size: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                height: 2.5rem;
                border-radius: 0.25rem;
                border-width: 1px;
                border-style: solid;
                border-image: initial;
                border-color: inherit;
                // border: none!important;
            }
        }
    }
}
@media print {
    body{
        width: auto;
        border: 0;
        margin: 0 5%;
        padding: 0;
        float: none;
        position: static;
        overflow: visible;
    }
}

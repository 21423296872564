.container-loadingmodal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    overflow: auto;
}

.modal-content {
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    margin: auto;
    padding: 20px;
    width: 100%;
    z-index: 200;
    background-attachment: rgba(0, 0, 0);
}

@import url(https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@500&display=swap);
body {
    padding-right: 0 !important;
    font-family: 'Baloo Da 2', cursive !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.phoneLoginButton {
    width: 100%;
}

.container-page-signin {
    height: 100vh;
    display: grid;
    background-color: #e0e5ec;
    grid-template-rows: 1fr 3fr;
}

.container-login-banner {
    align-items: center;
    display: grid;
    height: 140px;
    width: 140px;
    justify-content: center;
    border-radius: 20px;
    justify-self: center;
    align-self: center;
}

.container-login-form {
    min-width: 20%;
    display: grid;
    align-self: start;
    justify-self: center;
    margin-top: 20px;
    padding: 20px;
    border-radius: 20px;
}

.container-parallel-login-btn {
    display: grid;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.btn-submit {
    float: right;
    margin-top: 40px;
}

.btn-submit {
    float: right;
    margin-top: 40px;
}

.btn-signin {
    width: 100%;
    height: 36px;
    margin: 10px 0px;
}

.ant-input {
    background: #fff;
    /*border: none;*/
    height: 38px;
    /*box-shadow: inset 6px 6px 12px rgba(163,177,198 ,0.6),*/
    /*inset -6px -6px 12px  rgba(255,255,255, 0.5);*/
}

.ant-input:focus {
    /*border: none;*/
    height: 38px;
    /*box-shadow: inset 6px 6px 12px rgba(163,177,198 ,0.6),*/
    /*inset -6px -6px 12px  rgba(255,255,255, 0.5);*/
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
    background: none;
}

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.signin-container {
  display: grid;
  background: url(/static/media/rokkhi-admin.194ad153.png);
  background-position: center;
  background-repeat: no-repeat;
  min-height: 90vh;
  grid-template-columns: 1fr; }
  @media (max-width: 768px) {
    .signin-container {
      grid-template-columns: 1fr; } }
  .signin-container .vl {
    border-left: 2px solid #686868;
    height: 50vh;
    margin-top: 100px; }
    @media (max-width: 768px) {
      .signin-container .vl {
        display: none; } }
  .signin-container .image-container {
    margin: 60px auto; }
    @media (max-width: 768px) {
      .signin-container .image-container {
        display: none; } }
  .signin-container .form {
    background: #fff;
    padding: 40px;
    max-width: 600px;
    width: 100%;
    margin: 60px auto;
    border-radius: 15px;
    box-shadow: 0px 0px 15px -1px #525252;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .signin-container .signin-tab-group .ant-tabs-ink-bar {
    background: none !important; }
  .signin-container .signin-tab-group .ant-devider {
    margin: 0px 0px !important; }
  .signin-container .signin-tab-group .ant-tabs-tab-active {
    color: #fff !important;
    background: #ff3300 !important; }
  .signin-container .signin-tab-group .ant-tabs-nav .ant-tabs-tab {
    color: #837878;
    background: #d9d9d9;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 60px;
    margin: 0;
    padding: 15px 68%;
    text-decoration: none;
    font-size: 22px;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    @media (max-width: 768px) {
      .signin-container .signin-tab-group .ant-tabs-nav .ant-tabs-tab {
        padding: 20px 60%; } }
    .signin-container .signin-tab-group .ant-tabs-nav .ant-tabs-tab:last-child {
      border-radius: 0px 10px 10px 0px !important; }
    .signin-container .signin-tab-group .ant-tabs-nav .ant-tabs-tab:first-child {
      border-radius: 10px 0px 0px 10px !important; }
  .signin-container .signin-tab-group .ant-input {
    background: inherit;
    height: 50px;
    border: 1px solid #a0b3b0;
    color: #000000;
    border-radius: 10px; }
    .signin-container .signin-tab-group .ant-input:not(:first-child) {
      padding-left: 50px; }
    .signin-container .signin-tab-group .ant-input:not(:last-child) {
      padding-right: 35px; }
    .signin-container .signin-tab-group .ant-input :focus {
      outline: 0;
      border-color: #ff3300 !important; }
    .signin-container .signin-tab-group .ant-input :active {
      outline: 0;
      border-color: #ff3300 !important; }
  .signin-container .signin-tab-group .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #ff3300;
    border-right-width: 1px !important; }
  .signin-container h1 {
    text-align: center;
    color: #000000;
    font-weight: 300;
    margin: 0 0 40px; }
  .signin-container .button {
    margin-top: 10px;
    height: auto;
    border: 0;
    outline: none;
    border-radius: 10px;
    padding: 5px 0;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    background: #ff3300;
    color: #fff;
    transition: all 0.5s ease;
    -webkit-appearance: none; }
    .signin-container .button:hover, .signin-container .button:focus {
      color: #fff;
      border-color: #ff7253;
      background: #ff7253; }
  .signin-container i {
    color: #ff3300;
    font-size: 22px; }
  .signin-container a {
    color: #000; }
  .signin-container .phone-login-button {
    background: #000; }
    .signin-container .phone-login-button:hover, .signin-container .phone-login-button:focus {
      color: #fff;
      background-color: #414141;
      background: #414141; }
  .signin-container .button-block {
    display: block;
    width: 100%; }

@charset "UTF-8";
.toggle-container {
  width: 115px;
  background-color: #dbdbdb;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 40px;
  border-radius: 6px;
  position: relative; }
  .toggle-container::after {
    content: '\9AC\9BE\982\9B2\9BE';
    position: relative;
    font-weight: 800;
    color: #000;
    top: 25%;
    left: 7%; }

.toggle-container-hide {
  width: 115px;
  background-color: #dbdbdb;
  cursor: pointer;
  border-radius: 6px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 40px;
  position: relative; }
  .toggle-container-hide::after {
    content: 'English';
    position: relative;
    font-weight: 800;
    color: #000;
    top: 25%;
    left: 57%; }

.dialog-button {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #000;
  color: white;
  padding: 12px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-width: unset;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(31, 31, 31, 0.25);
  position: absolute;
  left: 50px;
  transition: all 0.3s ease; }

.disabled {
  background-color: #000;
  left: -2px; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.container-app-topbar {
  color: black;
  font-weight: bold; }

.app-topbar {
  background: #fff;
  border-radius: 4px;
  color: #1f2833;
  height: 100px;
  margin: 5px 0px 0px 0px;
  display: grid;
  align-items: center;
  grid-template-columns: 21fr 1fr 1fr 1fr;
  padding: 0 20px;
  grid-column-gap: 20px; }

.app-container-mobile {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-rows: 10% 80% 10%; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-green {
  color: #4cc474; }

.text-green-deep {
  color: #45b369; }

.btn-green {
  background: #14ab72;
  border: #14ab72;
  transition: 1000ms;
  color: white; }

.btn-green:focus {
  background: #14ab72;
  border: #14ab72;
  cursor: pointer;
  color: white; }

.btn-green:hover {
  background: #14ab72;
  border: #14ab72;
  cursor: pointer;
  color: white; }

.btn-orange {
  background: #ff6741;
  border: #ff3300;
  transition: 1000ms;
  color: white; }

.btn-orange:focus {
  background: #ff3300;
  border: #ff3300;
  cursor: pointer;
  color: white; }

.btn-orange:hover {
  background: #ff3300;
  border: #ff3300;
  cursor: pointer;
  color: white; }

.btn-red {
  background: #f55f55;
  color: white; }

.btn-red:hover {
  background: #df4338;
  transition: 1000ms;
  color: white;
  cursor: pointer; }

.neumorphic-card {
  box-shadow: 6px 6px 12px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5); }

.card {
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  padding: 10px; }

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s; }

.card:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2); }

.container-parallel-two {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr; }

.container-label-input {
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-row-gap: 5px;
  margin: 10px 0px; }

.form-image .ant-form-item-label {
  text-align: center; }

.ant-form-item {
  margin-bottom: 5px; }

.ant-form-item-label {
  line-height: 30px; }

.ant-transfer-list {
  width: 46%; }

.ant-transfer-list-header-title {
  font-size: 15px;
  font-weight: bold; }

.ant-btn-lg {
  padding: 0 4px !important; }

span.ant-transfer-list-search-action {
  display: grid;
  align-content: center; }

.ant-transfer-operation .ant-btn:first-child {
  height: 40px;
  width: 40px; }

.ant-transfer-operation .ant-btn {
  height: 40px;
  width: 40px; }

.ant-transfer-list {
  padding-bottom: 20px;
  height: 400px; }

.ant-transfer-operation {
  display: inline-grid;
  width: 6%; }

.ant-transfer-operation .ant-btn {
  display: grid;
  justify-self: center; }

.ant-transfer-list-content {
  margin-top: 20px; }

.ant-menu-submenu-inline:hover {
  color: #ff3300 !important; }

.ant-menu-submenu-active:hover {
  color: #efefef !important; }

.ant-menu-submenu:hover {
  color: #eeeedd !important; }

.ant-menu-sub.ant-menu-inline {
  /* background: aliceblue !important; */ }

.ant-menu-submenu-selected {
  color: #ff3300 !important;
  background: #F6F6FE !important;
  border-left: 3px solid #ff3300; }

.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #ff3300 !important; }

.ant-menu-item:hover {
  color: #ff3300 !important;
  background-color: #f4f4f4 !important; }

.ant-menu-submenu-selected > .ant-menu-item {
  background-color: #F6F6FE !important; }

.ant-menu-item-selected {
  background: #F6F6FE !important;
  color: #ff3300 !important; }

.ant-menu-submenu-title {
  color: #00090F;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 45px !important;
  padding-left: 15px !important; }

.ant-menu-submenu {
  margin-bottom: 0 !important;
  padding-top: 10px !important;
  background-color: #fff;
  border-bottom: 1px solid #eeecec; }

.ant-menu-item {
  border-top: 1px solid #eeecec;
  font-size: 16px !important;
  height: 45px !important;
  line-height: 46px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important; }

.ant-menu-submenu:focus {
  background-color: #d67070; }

.ant-menu-submenu-title:active {
  color: #ff3300; }

.ant-menu-submenu-title:hover {
  color: #ff3300; }

.ant-menu-inline .ant-menu-item::after {
  background: #ff3300 !important;
  border-right: #ff3300 3px solid !important; }

.ant-menu-submenu-arrow::before {
  background: #00090F !important; }

.ant-menu-submenu-arrow::after {
  background: #00090F !important; }

.ant-menu-submenu > .ant-menu-submenu-arrow::after {
  background: #ff3300 !important; }

.ant-menu-submenu > .ant-menu-submenu-arrow::before {
  background: #ff3300 !important; }

.ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #f5f8fe !important;
  padding: 10px !important; }

.ant-table-body {
  margin: 0 !important; }

a.ant-calendar-month-panel-next-year-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-month-panel-prev-year-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-year-panel-next-decade-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-year-panel-prev-decade-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-decade-panel-next-century-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-decade-panel-prev-century-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-next-year-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-prev-year-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-next-month-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-prev-month-btn {
  height: 10%;
  width: 10%; }

a.ant-calendar-month-select {
  height: 10%;
  width: 10%; }

a.ant-calendar-year-select {
  height: 10%;
  width: 10%; }

.ant-transfer {
  width: -webkit-fill-available; }

.ant-transfer-list-header-title {
  font-size: 15px;
  font-weight: bold; }

span.ant-transfer-list-search-action {
  display: grid;
  align-content: center; }

.ant-transfer-operation .ant-btn:first-child {
  height: 40px;
  width: 40px; }

.ant-transfer-operation .ant-btn {
  height: 40px;
  width: 40px; }

.ant-transfer-list {
  padding-bottom: 20px; }

.ant-transfer-operation {
  display: inline-grid;
  width: 6%; }

.ant-transfer-operation .ant-btn {
  display: grid;
  justify-self: center; }

.ant-calendar-picker {
  width: -webkit-fill-available; }

.ant-transfer-list-content {
  margin-top: 20px; }

.ant-checkbox-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 15px; }

.ant-table-placeholder {
  z-index: 0; }

.ant-layout {
  display: block;
  background: #e7e7e7 !important; }

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden; }

.ant-radio-group-large .ant-radio-button-wrapper {
  font-size: 14px; }

/* Ant Transfer Design For Responsive */
@media (max-width: 768px) {
  .ant-transfer-list {
    width: 100%; }
  .ant-transfer-operation {
    margin: 10px 0px;
    width: 100%; } }

.ant-transfer-operation .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  justify-content: center; }

.ant-transfer-operation .ant-btn .anticon {
  font-size: 40px; }

.ant-input[disabled] {
  color: black; }

.ant-input {
  color: black; }

.ant-tabs-nav .ant-tabs-tab:hover {
  color: none !important; }

.bill-checkbox .ant-checkbox-inner {
  top: 1px;
  left: 0;
  width: 35px;
  height: 35px; }

.bill-checkbox .ant-checkbox-inner::after {
  top: 45%;
  left: 20%;
  width: 11.714286px;
  height: 23.142857px; }

.ant-layout-sider {
  max-width: 250px !important;
  min-width: 250px !important; }
  .ant-layout-sider .ant-layout-sider-trigger {
    width: 250px !important;
    background-color: #002140; }

.ant-layout-sider-collapsed {
  max-width: 80px !important;
  min-width: 80px !important; }
  .ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: 80px !important; }

section.ant-layout::-webkit-scrollbar {
  display: none; }

aside.ant-layout-sider::-webkit-scrollbar {
  display: none; }

section.ant-layout {
  -ms-overflow-style: none;
  scrollbar-width: none; }

aside.ant-layout-sider {
  -ms-overflow-style: none;
  scrollbar-width: none; }

span.ant-input-suffix {
  top: 55% !important;
  right: 2px !important; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.container-icon-title {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 5px;
  text-align: start;
  align-items: center; }

.dropdown-basic-container {
  display: grid;
  padding-right: 5px;
  padding-bottom: 10px;
  grid-template-rows: auto 1fr;
  grid-column-gap: 5px;
  border-radius: 4px;
  background: #ffffff;
  align-content: center;
  align-items: center; }

.dropdown-basic-container-styled {
  display: grid;
  padding-right: 5px;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 5px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  align-content: center;
  align-items: center; }

.container-dropdown-content {
  display: grid;
  margin-bottom: 5px;
  grid-row-gap: 5px;
  padding: 10px 0px; }

.container-icon-dropdown {
  display: flex;
  margin-bottom: 6px; }

.container-icon-dropdown-styled {
  height: 100%;
  border-radius: 4px 0 0 4px;
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center; }

.btn-mild-green {
  color: #14ab72;
  background: #fff;
  border: 1px solid #14ab72; }

.btn-fill-green {
  color: #fff;
  background: #14ab72;
  border: 1px solid #14ab72; }

.btn-fill-purple {
  color: #fff;
  background: purple;
  border: 1px solid purple; }

.btn-fill-purple:hover {
  color: #fff;
  background: #3b013b;
  border: 1px solid purple; }

.btn-fill-purple:focus {
  color: purple;
  background: #fff;
  border: 1px solid purple; }

.btn-fill-green:hover {
  color: #fff;
  background: #14ab72;
  border: 1px solid #14ab72; }

.btn-fill-green:focus {
  color: #14ab72;
  background: #fff;
  border: 1px solid #14ab72; }

.btn-fill-black {
  color: #fff;
  font-size: 18px;
  min-width: 100px;
  background: #1f2833;
  border: 1px solid #1f2833;
  height: 45px; }
  .btn-fill-black i {
    font-size: 25px;
    font-weight: 800 !important;
    position: relative;
    top: 3px; }

.btn-fill-black:hover {
  color: #fff;
  background: #1f2833;
  border: 1px solid #1f2833; }

.btn-mild-green:hover {
  color: #fff;
  background: #14ab72;
  border: 1px solid #14ab72; }

.btn-mild-green:focus {
  color: #fff;
  background: #14ab72;
  border: 1px solid #14ab72; }

.btn-mild-red {
  color: #e53935;
  background: #fff;
  border: 1px solid #e53935; }

.btn-mild-red:hover {
  color: #fff;
  background: #e53935;
  border: 1px solid #e53935; }

.btn-mild-red:focus {
  color: #fff;
  background: #e53935;
  border: 1px solid #e53935; }

.btn-mild-blue {
  color: #0079bf;
  background: #fff;
  border: 1px solid #0079bf; }

.btn-mild-blue:hover {
  color: #fff;
  background: #0079bf;
  border: 1px solid #0079bf; }

.btn-mild-blue:focus {
  color: #fff;
  background: #0079bf;
  border: 1px solid #0079bf; }

.btn-fill-blue {
  color: #fff;
  background: #0079bf;
  border: 1px solid #0079bf; }

.btn-fill-blue:hover {
  color: #fff;
  background: #0079bf;
  border: 1px solid #0079bf; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.modal-content-container {
  display: grid;
  grid-row-gap: 10px; }

.image-container-modal {
  width: 130px;
  height: 130px; }

.modal-addguard-layout {
  display: grid;
  grid-row-gap: 20px; }

.modal-addworker-layout {
  display: grid;
  grid-column-gap: 20px; }

.modal-addworker-flatlist {
  margin: 20px 5px;
  height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  overflow: auto; }

.modal-addworker-flat-item-selected {
  padding: 10px 30px;
  text-align: center;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  border-radius: 4px;
  color: #fff;
  transition: 300ms;
  background: #0079bf; }
  .modal-addworker-flat-item-selected:hover {
    box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.modal-addworker-flat-item-unselected {
  padding: 10px 30px;
  text-align: center;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  color: #0079bf;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #0079bf;
  transition: 300ms; }
  .modal-addworker-flat-item-unselected:hover {
    color: #fff;
    background: #0079bf; }

.add-admin-item {
  display: grid;
  grid-template-columns: 1fr 5fr 2fr 2fr;
  grid-column-gap: 5px; }

.add-admin-item-nameflat {
  display: grid;
  grid-template-rows: 3fr 2fr; }

.btn-add {
  background: #fff;
  border: 1px solid;
  border-radius: 4px;
  transition: 300ms; }
  .btn-add:hover {
    color: #fff;
    background: #0079bf; }

.logo-image {
  display: none; }

.qr-code-header {
  margin-bottom: 20px; }
  .qr-code-header h2 {
    font-size: 26px; }
    .qr-code-header h2 span {
      font-weight: 600; }
  .qr-code-header h3 {
    font-size: 20px; }

.qr-code-footer {
  text-align: center;
  margin-top: 20px; }
  .qr-code-footer h2 {
    font-size: 20px; }
  .qr-code-footer h3 {
    font-size: 18px; }

@media print {
  html * {
    visibility: hidden; }
  body * {
    visibility: hidden;
    overflow: hidden; }
  .ant-modal-body * {
    visibility: visible; }
  .ant-modal-content {
    box-shadow: none; }
  .qr-code-footer {
    text-align: center; }
    .qr-code-footer h2 {
      font-size: 20px; }
    .qr-code-footer h3 {
      font-size: 18px; }
  canvas {
    height: 200px !important;
    width: 200px !important; }
  .logo-image {
    border-top: 1px solid gray;
    display: block;
    padding-top: 20px;
    margin-bottom: auto; }
    .logo-image img {
      width: 150px;
      max-width: 150px; }
  .print-btn {
    display: none; } }

.police-modal input {
  border: 1px solid #CBD5E0 !important; }

.police-modal .user-image {
  border: 2px solid #CBD5E0 !important; }

.print-btn {
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0; }

.print-btn:focus,
.print-btn:active {
  border: none;
  outline: none; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.bill-dashboard-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 30px;
  grid-column-gap: 30px; }

.dashboard-summary-item {
  border-radius: 5px;
  font-size: 30px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  background: #14ab72;
  color: #fff;
  padding: 10px; }

.ant-table-content {
  background-color: white; }

.ant-table-tbody > tr > td {
  word-break: break-word;
  -ms-word-break: break-all; }

.list-visitors-container {
  width: 96%;
  margin: 2%; }

.list-item-special-container {
  display: grid;
  grid-template-rows: 3fr 1fr;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37);
  padding: 10px 0px;
  margin-bottom: 10px; }

.list-item-visitors-container {
  display: grid;
  margin: 2% 5%;
  padding: 2%;
  border: none;
  border-bottom: 1px solid #e9ecea;
  grid-template-columns: 20% 70% 10%; }

.list-item-visitors-image {
  display: grid;
  align-items: center; }

.list-item-visitors-details {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr; }

.list-item-visitors-action {
  align-self: center; }

.image-user-small {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden; }

.image-user-large {
  align-content: center;
  justify-content: center;
  height: 400px;
  width: 400px; }

.column-center {
  display: grid;
  justify-items: center;
  justify-content: center; }

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
.bill-table-responsive thead tr th {
  background-color: #fff; }

.bill-table-responsive table,
.bill-table-responsive td,
.bill-table-responsive th {
  border: 1px solid #e8e8e8; }

.bill-table-responsive table {
  border: 0 !important; }
  .bill-table-responsive table th {
    border-top: 0; }

.bill-table-responsive td {
  padding: 5px 0px; }

.generate-table-responsive thead tr th {
  background-color: #fff;
  padding: 5px 0px; }

.generate-table-responsive table,
.generate-table-responsive td,
.generate-table-responsive th {
  border: 1px solid #e8e8e8; }

.generate-table-responsive td {
  padding: 5px 0px; }
  .generate-table-responsive td .ant-input {
    border: 0 !important; }

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .bill-table-responsive table,
  .bill-table-responsive thead,
  .bill-table-responsive tbody,
  .bill-table-responsive th,
  .bill-table-responsive td,
  .bill-table-responsive tr {
    display: block; }
  .bill-table-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .bill-table-responsive tr {
    border: 1px solid #ccc; }
  .bill-table-responsive td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%; }
  .bill-table-responsive td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap; }
  .bill-table-responsive td:nth-of-type(1):before {
    content: 'Select'; }
  .bill-table-responsive td:nth-of-type(2):before {
    content: 'Name'; }
  .bill-table-responsive td:nth-of-type(3):before {
    content: 'Description'; }
  .bill-table-responsive td:nth-of-type(4):before {
    content: 'Flat Name'; }
  .bill-table-responsive td:nth-of-type(5):before {
    content: 'Total'; }
  .bill-table-responsive td:nth-of-type(6):before {
    content: 'Paid'; }
  .bill-table-responsive td:nth-of-type(7):before {
    content: 'Month'; }
  .bill-table-responsive td:nth-of-type(8):before {
    content: 'Payment Status'; }
  .bill-table-responsive td:nth-of-type(9):before {
    content: 'Due Amount'; }
  .bill-table-responsive td:nth-of-type(10):before {
    content: 'Collect Amount'; }
  .bill-table-responsive td:nth-of-type(11):before {
    content: 'Arbitrary Data'; } }

.table-row-light {
  background-color: #ffffff; }

.table-row-dark {
  background-color: #fbfbfb; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.web-profile-button {
  margin-top: 35%;
  font-size: 36px; }

.sidebar-title {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 60px;
  text-align: center;
  color: #1f2833;
  font-weight: bold;
  align-self: center;
  font-size: 20px;
  display: grid;
  align-items: center;
  justify-items: center; }

.sidebar-logo-container {
  height: 40px;
  width: 40px;
  display: grid;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  margin: 11px; }

.sidebar-item-container {
  width: 100%; }

.icon-sidebar-layout {
  justify-content: center;
  display: grid;
  grid-template-columns: 30% 50%;
  grid-column-gap: 5px;
  text-align: start;
  padding: 10px 10%;
  transition: 300ms;
  color: #a9b4b4; }

.icon-sidebar-layout:hover {
  background: black;
  color: #fff; }

.icon-sidebar-layout:focus {
  background: black;
  color: #fff; }

.icon-sidebar-layout-mobile {
  height: 100%;
  display: grid;
  text-align: start;
  padding: 20px 0px;
  align-content: center;
  justify-content: center;
  color: #fbffff; }

.icon-sidebar-layout-mobile:hover {
  height: 100%;
  display: grid;
  text-align: start;
  padding: 20px 0px;
  background: #ceddda;
  align-content: center;
  justify-content: center;
  color: #0b0c0c; }

a {
  width: 100%; }

a:hover {
  text-decoration: none; }

.text-sidebar {
  text-align: start; }

.topbar {
  width: 85%;
  height: 10%;
  background: #fff;
  display: grid;
  position: fixed;
  top: 0;
  grid-template-columns: 86% 10%;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.ant-calendar-picker-clear {
  display: none; }

.ant-tag {
  font-size: 16px !important;
  padding: 2px 7px; }

.topbar-mobile {
  width: 100%;
  height: 10%;
  background: #fff;
  display: grid;
  position: fixed;
  top: 0;
  grid-template-columns: 15% 65% 20%;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.topbar-pagetitle {
  text-align: center;
  padding-top: 7%; }

.topbar-menu {
  background: #fff;
  justify-self: right;
  padding: 5px 10px;
  border-radius: 0px;
  align-self: center;
  justify-self: end; }

.topbar-profile {
  background: #fff;
  justify-self: right;
  padding: 5px 10px;
  border-radius: 0px;
  align-self: center;
  justify-self: end; }

.icon-topbar {
  border: 1px solid #37474f;
  border-radius: 3px;
  align-self: center;
  justify-self: center;
  padding: 10px;
  transition: 300ms; }

.icon-topbar:hover {
  background: #37474f;
  color: #fff; }

.btn-logout {
  width: 100%;
  margin-top: 15px;
  outline: none;
  font-weight: 600;
  background-color: #ff3300;
  color: #fff; }

.btn-logout:hover {
  background-color: #ff3300;
  color: #fff;
  outline: none;
  border: 0; }

.btn-profile {
  background: #fff;
  padding: 5px 0px;
  width: 100%;
  color: #37474f;
  letter-spacing: 0.25px;
  margin: 16px 0 0;
  border: 1px solid #dadce0;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
  outline: none; }

.btn-profile:hover {
  background-color: #f7f8f8; }

header.ant-layout-header {
  padding: 0 20px 10px 0px; }

span.ant-calendar-picker {
  width: 100%; }

.app-logo {
  height: 35px; }

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s; }

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff; }

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px; }

.site-layout-background {
  background: #f8e9e9; }

.site-layout-background span {
  display: block;
  align-self: end;
  padding: 15px; }

.perspective-small-card {
  background: #fefefe;
  display: inline-block;
  border: 1px solid lightgray;
  margin: 10px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  border-radius: 4px;
  cursor: pointer;
  background-position: left;
  transition: all 0.5s; }

.perspective-small-card h3 {
  text-align: center;
  margin: 0;
  font-weight: 600;
  font-size: 14px; }

.perspective-topbar {
  display: flex; }

.active-perspective-card {
  border: 1px solid gray;
  background: #F6F6FE;
  /* fallback for old browsers */
  color: #000; }

.active-perspective-card h3::after {
  content: '\2713';
  display: inline-block;
  vertical-align: top;
  line-height: 1.5em;
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 4px;
  background-color: #ff3300;
  margin-left: 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff; }

.header-container {
  display: grid;
  grid-template-columns: 1fr 23fr;
  justify-content: center;
  align-items: center; }

.header-container:first-child {
  margin-bottom: 5px; }

.header-container img {
  width: 10px;
  height: 10px;
  margin-right: 5px; }

.perspective-small-card:active {
  box-shadow: rgba(0, 0, 0, 0.5) 0 3px 10px 0;
  transition: all 0.8s; }

.show-perspective {
  background-color: #F6F6FE;
  border: 1px solid gray; }

.show-perspective h3::after {
  content: '\21C5';
  display: inline-block;
  vertical-align: top;
  line-height: 1.5em;
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 4px;
  margin-left: 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #ff3300; }

.container-loadingmodal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    overflow: auto;
}

.modal-content {
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    margin: auto;
    padding: 20px;
    width: 100%;
    z-index: 200;
    background-attachment: rgba(0, 0, 0);
}

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.info-card-container {
  display: grid;
  border-radius: 4px;
  background: #ffffff;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  max-height: 150px; }
  @media (max-width: 768px) {
    .info-card-container {
      margin: 5px 0px;
      padding: 10px 0px 0px 0px; } }

.container-left-colored {
  grid-template-columns: 2fr 3fr; }

.container-backfill-lefticon {
  grid-template-columns: auto 2fr; }

.container-backfill-righttopicon {
  grid-template-columns: 1fr auto; }

.info-card-icon {
  display: grid;
  border-radius: 4px 0px 0px 4px;
  align-items: center;
  align-content: center;
  justify-content: center; }

.icon-background-topright {
  margin: 5px;
  align-self: start; }

.icon-background-left {
  margin-left: 20px; }

.icon-boxfill-topleft {
  height: 70px;
  width: 70px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 2px;
  margin-left: 15px;
  margin-top: -15px;
  box-shadow: 1px 2px 4px 0px rgba(55, 71, 79, 0.37); }

.special-container {
  background: #ffffff;
  height: auto;
  grid-template-rows: 75% auto 25%; }
  @media (max-width: 768px) {
    .special-container {
      margin: 5px 0px;
      padding: 10px; } }

.container-boxfill-lefticon {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-radius: 4px 4px 0px 0px; }

.info-card-data-boxfill {
  margin-right: 5px;
  display: grid;
  padding: 5px;
  grid-row-gap: 0px;
  align-items: center;
  text-align: end; }

.info-card-data-divider-special {
  height: 1px;
  background: #c7c7c7;
  margin: 0% 5%; }

.boxfill-bottom-container {
  background: #fff;
  border-radius: 0px 0px 4px 4px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 3px;
  align-items: center;
  align-content: center;
  justify-items: start;
  padding-left: 5%; }

.boxfill-icon-bottom-text {
  color: #37474f;
  margin-bottom: -0.5em;
  align-self: center; }

.top-fill-container {
  grid-template-rows: 1fr 3fr;
  background: #ffffff; }

.top-fll-title-icon {
  display: grid;
  color: #ffffff;
  border-radius: 4px 4px 6px 4px;
  grid-template-columns: 1fr auto;
  padding: 15px 10px;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px; }

.top-fill-data {
  display: grid;
  text-align: center;
  align-content: center;
  font-size: 32px;
  border-radius: 0px 0px 4px 4px; }

.info-card-data {
  width: 100%;
  display: grid;
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
  grid-template-rows: 2fr auto 3fr;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center; }

.info-card-data-background {
  width: 100%;
  display: grid;
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  align-content: center;
  align-items: center;
  justify-content: center; }

.info-card-data-title {
  width: 100%; }

.info-card-data-divider {
  height: 1px;
  background: #c7c7c7;
  width: 100%; }

.back-fill-right-icon {
  display: grid;
  grid-template-rows: 1fr 3fr;
  align-items: center;
  grid-column-gap: 5px;
  background: #ffffff;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  height: 80%;
  padding: 5px; }

.back-fill-right-icon-info {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  padding-left: 10px;
  text-align: left;
  align-items: center;
  justify-items: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.back-fill-right-icon-icon {
  display: grid;
  color: gray !important;
  align-self: center;
  margin: 5%; }

.back-fill-right-icon-title {
  color: gray;
  text-align: left;
  font-size: 14px; }

.back-fill-right-icon-data {
  color: #1f2833;
  font-size: 28px;
  font-weight: bold; }

.left-round-icon {
  display: grid;
  border-radius: 4px;
  background: #ffffff;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  padding: 10px 20px;
  grid-template-columns: auto 2fr;
  grid-column-gap: 20px;
  align-items: center; }
  @media (max-width: 768px) {
    .left-round-icon {
      margin: 5px 0px;
      padding: 10px; } }

.left-round-icon-icon {
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  border-radius: 30%;
  align-self: center;
  display: grid;
  align-items: center;
  justify-items: center; }

.left-round-icon-info {
  display: grid;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-content: center; }

.left-round-icon-data {
  font-size: 30px;
  font-weight: 500; }

.left-round-icon-title {
  font-size: 12px;
  color: gray;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(227, 225, 225, 0.67); }

.icon-bottom-info {
  display: grid;
  padding: 10px 20px;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  align-items: start;
  justify-items: center;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px; }
  @media (max-width: 768px) {
    .icon-bottom-info {
      margin: 5px 0px;
      padding: 10px; } }

.icon-bottom-info-icon {
  padding: 15px;
  margin-top: 5px;
  border-radius: 25%; }

.icon-bottom-info-info {
  display: grid; }

.icon-bottom-info-data {
  font-size: 30px;
  font-weight: 500; }

.icon-bottom-info-title {
  font-size: 14px;
  color: gray; }

.left-color-icon {
  display: grid;
  grid-template-columns: 2fr 5fr;
  align-items: center;
  grid-column-gap: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  padding: 5px; }
  @media (max-width: 768px) {
    .left-color-icon {
      margin: 5px 0px;
      padding: 10px; } }

.left-color-icon-icon {
  display: grid;
  align-self: center;
  margin: 10%;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  justify-items: center;
  padding: 15px; }

.left-color-svg-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px; }

.left-color-svg-icon {
  display: grid;
  align-items: center;
  margin: 5%;
  width: 60px;
  height: 60px;
  justify-items: center;
  padding: 10px;
  border-radius: 50%/50%; }

.left-color-icon-info {
  display: grid;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-content: center; }

.left-color-icon-title {
  color: gray;
  font-size: 14px; }

.left-color-icon-data {
  font-size: 24px;
  font-weight: 500; }

.form-decoration-title-container {
  display: grid;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding-bottom: 20px;
  grid-template-columns: 100px auto;
  border-bottom: 1px solid #e0e5ec; }

.form-decoration-icon {
  height: 70px;
  width: 70px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 2px;
  margin-left: 10px;
  margin-top: -30px;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  background-color: #ff3300; }

.form-decoration-title {
  font-weight: bold;
  font-size: 20px; }

.table-decoration-title-container {
  display: grid;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding-bottom: 40px;
  grid-template-columns: 45% auto; }

.table-decoration-icon {
  height: 60px;
  width: 80px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 2px;
  margin-left: auto;
  margin-top: -30px;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  background-color: #b7c2c2; }

.table-decoration-title {
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
  margin-top: -5px; }

.red-tag-button:active {
  border: none;
  outline: none; }

.red-tag-button:focus {
  border: none;
  outline: none; }

.red-tag-button:hover {
  box-shadow: 1px 1px 8px #fcdcdc;
  transition: 0.3s ease; }

.text-red {
  color: #fe3935; }

.text-black {
  color: #37474f; }

.text-blue {
  color: #0079bf; }

.text-orange {
  color: #ff8c00; }

.text-green {
  color: #4cc474; }

.text-magenta {
  color: #19b4b4; }

.text-green-deep {
  color: #45b369; }

.background-green {
  background: #1b9b70; }

.background-blue {
  background: #0079bf; }

.background-magenta {
  background: #19b4b4; }

.background-orange {
  background: #116466; }

.background-color {
  background: #eff1f1; }

.text-input-icon-container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 0.5px solid rgba(203, 207, 206, 0.404); }

.icon-text-input-box {
  font-size: 14px;
  margin-top: 10px; }

.text-input-background {
  padding: 5px;
  border: none;
  border-radius: 5px; }
  .text-input-background:focus {
    outline: 0; }

.title-over-input {
  font-size: 12px;
  text-align: start; }

.title-over-table {
  font-size: 25px;
  text-align: start;
  align-self: center; }

.page-visitor-topbar {
  display: grid;
  height: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2%;
  margin-top: 10px;
  margin-bottom: 5px;
  z-index: 199; }

.profile-container {
  background: #fff;
  margin: 20px 0px;
  padding: 20px 0px 80px 0px;
  border-radius: 12px; }
  .profile-container .profile-title {
    text-align: center; }
    .profile-container .profile-title img {
      height: 120px;
      width: 120px;
      border-radius: 50%; }
    .profile-container .profile-title h1 {
      font-size: 28px; }
  .profile-container .profile-info {
    border: 1px solid #dadce0;
    padding: 20px 10px;
    background: #020024;
    background: linear-gradient(90deg, #020024 0%, rgba(254, 254, 254, 0) 0%, #fae3e3 100%);
    box-shadow: 2px 2px 10px gray;
    border-radius: 8px;
    max-width: 700px;
    margin: 0px auto; }
    .profile-container .profile-info .info-row .single-row {
      padding: 15px 0px 15px 24px;
      display: grid;
      grid-template-columns: 3fr 6fr;
      grid-column-gap: 5%; }
    .profile-container .profile-info .info-row .border-bottom {
      margin-left: 24px; }
    .profile-container .profile-info .info-row:hover {
      background-color: #f0f1f1; }
      .profile-container .profile-info .info-row:hover .border-bottom {
        margin-left: 0px; }

.pricing-plan-container {
  background: #fff;
  margin: 20px 0px;
  border-radius: 12px;
  box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
  padding: 20px 3px 50px 30px;
  width: 100%; }
  .pricing-plan-container .wrapper {
    padding: 0 10%; }
    .pricing-plan-container .wrapper .pricing-container-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .pricing-plan-container .wrapper .pricing-container-title .title {
        width: 50%; }
        @media only screen and (max-width: 992px) {
          .pricing-plan-container .wrapper .pricing-container-title .title {
            width: 100%; } }
        .pricing-plan-container .wrapper .pricing-container-title .title h1 {
          font-size: 28px; }
    .pricing-plan-container .wrapper .plan-grid {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .pricing-plan-container .wrapper .plan-grid .pricing-title {
        width: 60%;
        display: grid;
        grid-column-gap: 15px;
        grid-template-columns: 1fr 1fr 1fr; }
        @media only screen and (max-width: 992px) {
          .pricing-plan-container .wrapper .plan-grid .pricing-title {
            width: 100%;
            grid-column-gap: 5%; } }
        .pricing-plan-container .wrapper .plan-grid .pricing-title .active_item {
          background-color: #14ab72;
          display: inline;
          padding: 30px 10px;
          color: #fff;
          font-size: 18px;
          text-align: center;
          cursor: pointer; }
        .pricing-plan-container .wrapper .plan-grid .pricing-title .plan_item {
          background: #ff3300;
          display: inline;
          padding: 30px 10px;
          color: #fff;
          font-size: 18px;
          text-align: center;
          cursor: pointer; }
    .pricing-plan-container .wrapper table {
      padding: 20px 0px;
      width: 100%;
      display: flex;
      flex-direction: column; }
      .pricing-plan-container .wrapper table tbody {
        display: flex;
        flex-direction: column; }
        .pricing-plan-container .wrapper table tbody tr:last-of-type {
          border-bottom: none; }
        .pricing-plan-container .wrapper table tbody tr {
          border-bottom: 1px solid #ccc;
          display: flex;
          align-items: center; }
          .pricing-plan-container .wrapper table tbody tr td:first-of-type {
            width: 40%;
            text-align: start;
            margin-left: 15px;
            position: relative; }
          .pricing-plan-container .wrapper table tbody tr td:nth-of-type(4) {
            margin-left: 12px; }
          .pricing-plan-container .wrapper table tbody tr td:nth-of-type(3) {
            margin-left: 15px; }
          .pricing-plan-container .wrapper table tbody tr td {
            width: calc(60% / 3);
            font-size: 18px;
            padding: 6px;
            text-align: center; }

.item_shadow {
  background: #bdf0dc;
  padding: 6px; }

.btn_view {
  display: none; }

.ant-alert-close-icon {
  left: 98%; }
  @media (max-width: 768px) {
    .ant-alert-close-icon {
      left: 95%; } }

.subscription-button {
  left: 90%;
  z-index: 12;
  right: 0;
  top: -53px;
  position: relative; }

.pricing-plan-card-container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
  padding: 30px;
  margin: auto 12%;
  align-items: center; }

.pricing-plan-card {
  box-shadow: 2px 2px 4px 0px #d3d3d3, -1px -1px 6px #d3d3d3;
  text-align: center;
  padding: 40px 20px;
  background-position: center;
  background-color: #fefefe;
  border-radius: 12px; }

.active-card {
  color: #000;
  box-shadow: 2px 2px 4px 0px #d3d3d3, -1px -1px 6px #d3d3d3;
  transform: scale(1.1);
  background-position: center;
  background: #fae3e3;
  /* fallback for old browsers */
  background: linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, #fae3e3 100%); }

.pricing-plan-card-items {
  padding: 0;
  text-align: left; }

.pricing-plan-card-items li {
  list-style-type: none;
  font-size: 18px;
  padding: 1px; }

.select-plan-active-btn {
  border: none;
  padding: 6px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  background-color: #ff3300;
  box-shadow: 2px 2px 4px gray;
  transition: 0.3s; }
  .select-plan-active-btn:hover {
    background-color: #bb2500; }
  .select-plan-active-btn:focus, .select-plan-active-btn:active {
    outline: none;
    border: none; }

.select-plan-btn {
  color: #000;
  border: none;
  padding: 6px 24px;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 2px 2px 4px #858585;
  background: #fae3e3;
  /* fallback for old browsers */
  background: linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, #fae3e3 100%);
  transition: 0.4s ease; }
  .select-plan-btn:hover {
    opacity: 0.7; }
  .select-plan-btn:focus, .select-plan-btn:active {
    outline: none;
    border: none; }

.page-perspective-container {
  background-color: #fff;
  margin-top: 20px;
  padding: 40px 100px;
  height: 100vh; }

.page-perspective-card-container {
  border-radius: 12px;
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); }

.perspective-card {
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 20px 30px;
  margin-top: 20px;
  box-shadow: 1px 1px 4px lightgray; }

.perspective-card h1 {
  font-size: 26px; }

.cart-container {
  background: #fff;
  margin: 20px 0px;
  border-radius: 12px;
  box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
  padding: 20px 3px 50px 30px;
  width: 100%;
  display: flex;
  justify-content: center; }

.cart-card {
  background-color: #fefefe;
  padding: 20px;
  height: 40vh;
  box-shadow: 4px 4px 6px #d3d3d3, -2px -2px 6px #d3d3d3;
  border-radius: 8px; }

.cart-item-card h1 {
  margin: 0 10px 0 0; }

.cart-item-card {
  background: #fff;
  border: 1px solid lightgray;
  padding: 6px 12px;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.separator {
  display: flex;
  align-items: center;
  text-align: center; }

.separator::before,
.separator::after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid lightgray; }

.separator:not(:empty)::before {
  margin-right: 0.25em; }

.separator:not(:empty)::after {
  margin-left: 0.25em; }

.create-bill-package {
  width: inherit !important; }
  .create-bill-package .modal-content-container {
    display: grid;
    grid-row-gap: 10px; }
  .create-bill-package .ant-modal-content {
    max-height: calc(100vh - 7.5rem) !important;
    height: 100% !important;
    width: 100vw !important;
    max-width: 64rem !important; }

.error-description-container {
  text-align: center;
  overflow: hidden; }
  .error-description-container h3 span {
    color: #ff3300; }

.print-source {
    display: none;
    /* text-align: center; */
}

@media print {
    html * {
        visibility: visible;
    }
    body * {
        visibility: visible;
        overflow: hidden;
    }
    .print-source {
        display: block;
        color: black;
        display: flex;
        justify-content: center;
    }
}
@page {
    margin-top: 1cm;
    margin-bottom: 1cm;
}

.police-verification {
  width: inherit !important; }
  .police-verification .modal-content-container {
    display: grid;
    grid-row-gap: 10px; }
  .police-verification .ant-modal-content {
    max-height: calc(100vh - 7.5rem) !important;
    height: 100% !important;
    width: 100vw !important;
    max-width: 64rem !important; }

.police-modal {
  word-wrap: normal;
  color: #000;
  padding: 20px 10px; }
  .police-modal .top-section {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: repeat(1, 0.75fr 1.25fr 1fr);
    width: 100%; }
    .police-modal .top-section .flex-common .flex-label-input {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem; }
      .police-modal .top-section .flex-common .flex-label-input label {
        font-size: 1rem;
        padding-right: 8px;
        padding-bottom: 4px;
        opacity: 1;
        font-weight: 500;
        text-align: left;
        vertical-align: middle;
        display: inline-block;
        width: 25%; }
      .police-modal .top-section .flex-common .flex-label-input input {
        width: 75%;
        display: flex;
        align-items: center;
        position: relative;
        transition: all 0.2s ease 0s;
        outline: none;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 2.5rem;
        border-radius: 0.25rem;
        border-width: 1px;
        border-style: solid;
        border-image: none;
        border-image: initial;
        border-color: inherit; }
    .police-modal .top-section .header-left {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .police-modal .top-section .header-left .user-image {
        border-width: 2px;
        border-radius: 0.5rem;
        height: 180px !important;
        max-width: 20rem;
        border: none !important;
        display: flex;
        justify-content: center; }
        .police-modal .top-section .header-left .user-image img {
          max-width: 100%;
          height: auto; }
    .police-modal .top-section .header-middle {
      width: 100%; }
      .police-modal .top-section .header-middle .police-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem; }
        .police-modal .top-section .header-middle .police-title img {
          height: 42px;
          margin-right: 1rem; }
        .police-modal .top-section .header-middle .police-title h2 {
          font-size: 1.25rem;
          line-height: 1.25;
          font-weight: 700; }
    .police-modal .top-section .header-right {
      width: 100%; }
  .police-modal .form-header {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3rem; }
    .police-modal .form-header u {
      font-size: 24px;
      line-height: 1.25;
      font-weight: 700;
      text-align: center;
      text-decoration: none; }
  .police-modal .form-element {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .police-modal .form-element .table-section {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .police-modal .form-element .table-section .table-title {
        margin-bottom: 0.5rem;
        font-size: 1rem; }
      .police-modal .form-element .table-section table tr td {
        text-align: center;
        border: 1px solid #777777;
        padding: 6px;
        font-size: 1rem; }
        .police-modal .form-element .table-section table tr td input {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          transition: all 0.2s ease 0s;
          outline: none;
          -webkit-appearance: none;
             -moz-appearance: none;
                  appearance: none;
          font-size: 1rem;
          padding-left: 1rem;
          padding-right: 1rem;
          height: 2.5rem;
          border-radius: 0.25rem;
          border-width: 0px;
          border-style: none;
          border-style: initial;
          border-image: none;
          border-image: initial;
          border-color: inherit;
          background-color: white; }
    .police-modal .form-element .emergency-section {
      display: flex;
      width: 100%; }
      .police-modal .form-element .emergency-section .emergency-title {
        font-size: 1rem;
        margin-bottom: 0.5rem; }
    .police-modal .form-element .form-item-warp {
      display: grid;
      grid-gap: 1.5rem;
      gap: 1.5rem;
      width: 100%; }
    .police-modal .form-element .item-no {
      width: 2.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .police-modal .form-element .label-input-wrap {
      width: 100%; }
      .police-modal .form-element .label-input-wrap label {
        font-size: 1rem;
        padding-right: 12px;
        padding-bottom: 4px;
        opacity: 1;
        font-weight: 500;
        text-align: left;
        vertical-align: middle;
        display: inline-block; }
      .police-modal .form-element .label-input-wrap input {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        transition: all 0.2s ease 0s;
        outline: none;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 2.5rem;
        border-radius: 0.25rem;
        border-width: 1px;
        border-style: solid;
        border-image: none;
        border-image: initial;
        border-color: inherit; }

@media print {
  body {
    width: auto;
    border: 0;
    margin: 0 5%;
    padding: 0;
    float: none;
    position: static;
    overflow: visible; } }

.police-form-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem; }

.card-container {
  display: flex;
  background-color: #fff;
  min-height: 83vh;
  justify-content: space-evenly;
  padding: 40px; }
  @media (max-width: 768px) {
    .card-container {
      display: flex;
      flex-direction: column; } }
  .card-container .card-body {
    background-color: #f3f3f3;
    padding: 28px 6px;
    box-shadow: -2px 2px 4px #e9e8e8, -2px -2px 4px #e9e8e8;
    border-left: 5px solid;
    border-image: linear-gradient(0deg, #fcc4b5, #ff3300);
    border-image-slice: 2;
    transition: all 0.3s;
    height: 230px;
    transition-timing-function: ease;
    cursor: pointer;
    text-align: center; }
    .card-container .card-body img {
      width: 30%; }
    .card-container .card-body h1 {
      margin-top: 12px;
      font-size: 32px; }
  .card-container .card-body:hover {
    box-shadow: -2px -2px 10px #e9e8e8, 2px 2px 10px #e9e8e8;
    background-color: #fff;
    transform: scale(1.1); }

.tenant-modal-form {
  width: inherit !important; }
  .tenant-modal-form .modal-content-container {
    display: grid;
    grid-row-gap: 10px; }
  .tenant-modal-form .ant-modal-content {
    max-height: calc(100vh - 7.5rem) !important;
    height: 100% !important;
    width: 90vw !important;
    max-width: 80rem !important; }

.dashboard-container {
    margin: 20px;
    margin-top: 0;
    display: grid;
    grid-template-rows: auto auto 1fr;
    /* background-color: #fff; */
    /* padding: 20px; */
}

.dashboard-info-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}

.dashboard-item-container {
    display: grid;
    /* margin-top: 20px; */
    grid-template-rows: 1fr 3fr;
    /* flex-direction: column; */
    padding: 5px 10px;
    background: rgb(254, 254, 254);
    border-radius: 8px;
    border: 1px solid lightgray;
    /* justify-items: center; */
    /* border: 1px solid lightgray; */
    /* border-bottom: 3px solid #001a31; */
    /* box-shadow: 2px 2px 12px rgb(184, 184, 184); */
    cursor: pointer;
}
.bar-chart-container {
    /* box-shadow: 2px 2px 16px rgb(184, 184, 184); */
    border: 1px solid lightgray;
    border-radius: 8px;
    margin-top: 20px;
}
/* .dashboard-item-container:hover {
    transform: scale(1.05);
    transition: 900ms ease-in-out;
    cursor: pointer;
} */

.info {
    text-align: end;
    font-size: 48px;
    font-weight: bolder;
}

.icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
}

.dashboard-entrance-history {
    background: #fff;
    margin-top: 20px;
    border-radius: 4px;
}

.title {
    font-size: 20px;
    font-weight: 700;
}
.dash-main-container {
    display: grid;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px;
    grid-template-columns: 5fr 1fr;
}
.dashboard-side-item-container {
    /* margin-top: 20px; */
    border-radius: 10px;
    /* padding: 20px 0; */
}
.side-item {
    background-color: #fff;
    padding: 18px 14px;
    /* border-radius: 8px; */
    /* margin-top: 20px; */
    /* box-shadow: 2px 2px 12px rgb(184, 184, 184); */
    border-bottom: 1px solid lightgray;
}
.side-item ul {
    list-style-type: none;
    padding: 0;
}
.side-item ul li {
    font-size: 16px;
    padding: 5px 0;
    font-weight: 600;
}
.side-item h1 {
    font-size: 22px;
}
.side-item-body ul li {
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}
.side-item-header {
    display: flex;
    align-items: center;
    padding: 6px 0;
}
.side-item-icon {
    /* background-color: rgb(240, 240, 240); */
    padding: 8px 6px;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}
.side-item-header img {
    width: 20px;
    height: 25px;
}
.side-item-header h1 {
    color: #000D64;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}
.side-item-small-header {
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between;
    align-items: center;
}
.side-item-small-header h1 {
    margin: 0;
    padding: 2px;
}
.side-item-counter span {
    background-color: #0004A1;
    padding: 1px 18px;
    color: #fff;
    font-size: 14px;
    border-radius: 12px;
    font-weight: 500;
}
.button-container {
    text-align: center;
}
.card-header {
    display: flex;
    padding: 4px;
    align-items: center;
}
.card-header h1 {
    margin: 0;
    /* margin-bottom: -10px; */
    margin-left: 10px;
    font-size: 22px;
}
.list-items ul {
    padding: 0 12px;
    margin: 0;
}
.list-items ul li {
    list-style-type: none;
    font-size: 16px;
}
.dashboard-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
}
.dashboard-card-body h1 {
    font-size: 26px;
    text-align: center;
    margin: 0;
}
.bottom-bar-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    padding: 14px 36px;
    background: #fff;
    border-radius: 10px;
    height: 110px;
    flex-wrap: wrap;
    /* box-shadow: 2px 2px 6px lightgray; */
}

.bottom-item-container {
    background-color: rgb(245, 245, 245);
    padding: 10px 22px;
    margin: 4px;
    border-radius: 8px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* box-shadow: inset -2px -2px 6px rgb(230, 230, 230); */
}
.bottom-item-container img {
    /* width: 40px;
    height: 70px; */
    width: 50px;
    padding: 4px;
    margin-right: 10px;
}
.bottom-item-container h2 {
    font-size: 26px;
    font-weight: bold;
}
.bottom-item-container p {
    font-size: 18px;
    font-weight: lighter;
    color: gray;
}
.bottom-item-container h2,
p {
    margin: 0;
}

@media only screen and (max-width: 1280px) {
    .dashboard-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .card-header {
        justify-content: center;
    }
    .card-header h1 {
        font-size: 18px;
    }
    .bottom-bar-container {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .dashboard-side-item-container {
        display: none;
    }
    .dash-main-container {
        grid-template-columns: 6fr;
    }
}

@media only screen and (max-width: 860px) {
    .dashboard-card-body {
        display: flex;
        flex-direction: row;
    }
    .dashboard-info-container {
        display: flex;
        flex-direction: column;
    }
    .dashboard-container {
        margin: 0 auto;
    }
    .dashboard-side-item-container {
        display: none;
    }
    .bottom-bar-container {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}

.balance-sheet-container {
    background-color: #fff;
    padding: 10px;
    /* margin: 20px; */
    border-radius: 4px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}

